import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { image2, image1, image3, prev, next, sponsored1, suggested1, suggested2, suggested3 ,leftArrow,rightArrow} from "./assets";
import React, { createRef } from 'react'
export const configJSON = require("./config.js");


interface ArrowProps {
  onClick: () => void;
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  handlePodcastId: (id:string) => void;
  handleAddAudio: (playable_type: string, playable_id: string) => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  continueListeningPodcasts: any;
  placeholdersNeeded: number
  sponsoredPodcasts: any
  suggestedPodcasts: any;
  categoryIds : number[]
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  audioLastPlayedApiCallId : string = "";  
  suggestedForYouCallId : string = "";
  saveEpisodeCallId : string = "";
  unSaveEpisodeCallId : string = "";
  continueListeningCallId : string = "";
  userDetailsApiCallId : string = "";
  apiPodcastEpisodesCallId : string = "";
  scrollRef: any = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      continueListeningPodcasts: [],
      placeholdersNeeded: typeof window !== 'undefined' && window.innerWidth > 1520 ? 2 : 1,
      sponsoredPodcasts: [],
      suggestedPodcasts: [],
      categoryIds : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.userDetails(); 
    this.getContinueListeningData();
    this.setState({
      continueListeningPodcasts: [],
      sponsoredPodcasts: [
        {id: 1, src: sponsored1, alt: "image1", episode: "Episode 12", name: "The true"}, 
        {id: 2, src: sponsored1, alt: "image2", episode: "Episode 12", name: "The true"},
        {id: 3, src: sponsored1, alt: "image3", episode: "Episode 12", name: "The true"},
        {id: 4, src: sponsored1, alt: "image4", episode: "Episode 12", name: "The true"},
        {id: 5, src: sponsored1, alt: "image5", episode: "Episode 12", name: "The true"},
        {id: 6, src: sponsored1, alt: "image6", episode: "Episode 12", name: "The true"},
        {id: 7, src: sponsored1, alt: "image7", episode: "Episode 12", name: "The true"},
      ],
  
    })
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start  
    if(this.userDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForuserSettingsApi(message);
    }  
    if(this.continueListeningCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForContinueListening(message);
    }
    if(this.suggestedForYouCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForSuggestedForYou(message);
    }
    if(this.saveEpisodeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForSaveEpisode(message);
    }
    if(this.unSaveEpisodeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForUnSaveEpisode(message);
    }
    if(this.apiPodcastEpisodesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.podcast) {
        setStorageData("podcast_id", responseJson.podcast.data.id);
      }     
    }
   
    // Customizable Area End
  }
  // Customizable Area Start

  userDetails = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForuserSettingsApi = async (message: Message) => {
    if (this.userDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson.data) {
        console.log("responseData.data.dashboard", responseJson.data.attributes.podcast_categories_ids)
        this.setState({ categoryIds: responseJson.data.attributes.podcast_categories_ids });
        this.getSuggestedForYou(); 
        if (responseJson.data.attributes.roles.some((role:any) => role.name === "host")) {
          this.getPodcastEpisodes();
        } 
      }
    }
  };
  getContinueListeningData =async() =>{
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.continueListeningCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.continueListening}`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  }

  getSuggestedForYou =async() =>{
    let token = await getStorageData("token");
    let arr = this.state.categoryIds
    console.log("category_id",this.state.categoryIds);
    
    
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.suggestedForYouCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.suggestedForYou}?category_ids[]=${arr.join(',')}&per_page=6&page=1`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  }

  
  handleResForSuggestedForYou = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.data) {
            console.log(responseJson.data,"Trending suggeseted");            
            this.setState({suggestedPodcasts :responseJson.data })
    }
  };

  handleResForSaveEpisode = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.data.id) {
            this.getSuggestedForYou()
    }
  };

  handleResForUnSaveEpisode  = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Successfully Unsaved") {
      this.getSuggestedForYou()                     
    }
  };

  handleResForContinueListening = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.data) {
            this.setState({continueListeningPodcasts :responseJson.data})
    }
  };

  handleUnSave = async(id:number) =>{
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unSaveEpisodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unSaveEpisode}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSave = async(id:number) =>{
    console.log(id,"save id");
    
    
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        saveable_type: "BxBlockLogin::Episode", 
        saveable_id: id
    }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveEpisodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveEpisode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPodcastEpisodes = async () => {
    let token = await getStorageData("token");

    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPodcastEpisodesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.podcastData
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  getEmptyPlaceholders() {
    return Array.from({ length: this.state.placeholdersNeeded }, (_, index) => ({
      id: `placeholder-${index + 1}`,
      src: '',
      alt: 'Empty screen',
      episode: '',
      name: '',
      progress: 0,
    }));
  }

  getContinueListeningSlickSettings(){
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0

    return(
       {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: React.createElement(this.nextContinueArrow, { onClick: () => {}, innerWidth }),
        prevArrow: React.createElement(this.prevContinueArrow, { onClick: () => {}, innerWidth }),
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
        ]
       }
    )
  }

  getSponsoredSlick() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  
    return {
      dots: true,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: React.createElement(this.nextSponsoredArrow, { onClick: () => {}, innerWidth }),
      prevArrow: React.createElement(this.prevSponsoredArrow, { onClick: () => {}, innerWidth }),
    };
  }

  getSuggestedSlickSettings() {
  const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  const { suggestedPodcasts } = this.state;

  return {
    dots: true,
    infinite: false, 
    speed: 800,
    slidesToShow: suggestedPodcasts.length,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: React.createElement(this.nextSuggestedArrow, { onClick: () => {}, innerWidth }),
    prevArrow: React.createElement(this.prevSuggestedArrow, { onClick: () => {}, innerWidth }),
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: suggestedPodcasts.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: suggestedPodcasts.length >= 5 ? 5 : suggestedPodcasts.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: suggestedPodcasts.length >= 4 ? 4 : suggestedPodcasts.length,
          slidesToScroll: Math.min(4, suggestedPodcasts.length),
        },
      },
    ],
  };
}

  

  prevSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props
  
    return (
      <div onClick={onClick} 
      style={{
        height: "50px", 
        width: "50px", 
        borderRadius: "50%", 
        background: "#FF0807", 
        position: 'absolute',
        top:"50%",
        left: "-2%",
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
      }}>
      <img 
        src={leftArrow} 
        style={{
          width:"100%",
          height:"100%"
        }} 
        alt="Arrow"
      />
      </div>
    );
  };

  nextSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return (
      <div onClick={onClick} style={{
        height: "50px", width: "50px",
        borderRadius: "50%",
        background: "#FF0807",
        position: 'absolute',
        right: "-2%",
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
      }}>
        <img
          src={rightArrow}
          style={{
            width: "100%",
            height: "100%"
          }}
          alt="Arrow"
        />
      </div>
    );
  }

  prevContinueArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <div onClick={onClick} style={{
        height: "50px", width: "50px", 
        borderRadius: "50%", 
        background: "#FF0807", 
        position: 'absolute',
        top: '50%',
        left: "-2%",
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
      }}>
      <img 
        src={leftArrow} 
        style={{
          width:"100%",
          height:"100%"
        }} 
        alt="Arrow"
      />
      </div>
    )
  }

  nextContinueArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <div onClick={onClick} style={{
        height: "50px", width: "50px",
        borderRadius: "50%", 
        background: "#FF0807", 
        position: 'absolute',
        right: "-2%",
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
    }}>
    <img 
      src={rightArrow} 
      style={{
        width:"100%",
        height:"100%"
      }} 
      alt="Arrow"
    />
    </div>
    )
  }

  prevSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <div onClick={onClick} style={{
        height: "50px", 
        width: "50px", 
        borderRadius: "50%", 
        background: "#FF0807", 
        position: 'absolute',
        top:"40%",
        left: "-2%",
        transform: 'translateY(-40%)',
        cursor: 'pointer',
        zIndex: 1,
      }}>
      <img 
        src={leftArrow} 
        style={{
          width:"100%",
          height:"100%"
        }} 
        alt="Arrow"
      />
      </div>
    )
  }

  nextSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <div onClick={onClick} style={{
        height: "50px", 
        width: "50px",
        borderRadius: "50%",
        background: "#FF0807", 
        position: 'absolute',
        right: "-2%",
        top:"40%",
        transform: 'translateY(-40%)',
        cursor: 'pointer',
        zIndex: 1
    }}>
    <img 
      src={rightArrow} 
      style={{
        width:"100%",
        height:"100%"
      }} 
      alt="Arrow"
    />
    </div>
    )
  }

  // Customizable Area End
}
