import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from 'react'
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { micImg } from "./assets";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void;
    handleAddAudio: (playable_type: string, playable_id: string) => void;
}

interface SS {
    id: any;
}
  
interface S {
    fullPlaylistInfo: IFullPlaylist;
    isLoading: boolean;
    showFullPlaylist: boolean;
}

interface PlaylistDetail {
  cover_image: string | undefined;
  name: string;
  episode_type: string;
  description: string;
  episode_number: number;
  song_added: boolean;
  episode_content: string | null;
  episodeId: string;
}

interface IFullPlaylist {
  title: string;
  image: string | undefined;
  songs: PlaylistDetail[];
}

export default class FullPlayListController extends BlockComponent<Props, S, SS> {

  getFullPlaylistApiCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
        fullPlaylistInfo: {
          title: "",
          image: micImg,
          songs: [
            {
              cover_image: undefined,
              name: "",
              episode_type: "",
              description: "",
              episode_number: 0,
              song_added: false,
              episode_content: null,
              episodeId:""
            },
          ],
        },
        isLoading: false,
        showFullPlaylist: false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getFullPlaylist();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(this.getFullPlaylistApiCallId === apiRequestCallId) {
        this.handleFullplaylistResponse(from, message);
      }
    }
  }

  handleBack = () => {
    this.props.handleCommonPath("Playlist")
  }

  handleNavigateToPlaylist = () => {
    if(this.state.fullPlaylistInfo.title === "Main Playlist") {
      this.props.handleCommonPath("AddPlayList");
    }
    else {
      this.props.handleCommonPath("OtherPlayListEdit");
    }
  }
  
  getFullPlaylist = async() => {
    this.setState({isLoading: true});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getFullPlaylistApiCallId = requestMessage?.messageId

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    const getPlaylistId = await getStorageData("selected_playlist_id")

    let playlistId = getPlaylistId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.songListApiEndPoint}/${playlistId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFullplaylistResponse = async (from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiResponse?.playlist?.data?.id){
      const transformedPlaylist: IFullPlaylist = {
        title: apiResponse.playlist.data.attributes.title,
        image: apiResponse.playlist.data.attributes.image ? apiResponse.playlist.data.attributes.image : micImg,
        songs: apiResponse.playlist.data.attributes.songs.data.map((song:any) => ({
          episodeId: song.id,
          cover_image: song.attributes.cover,
          name: song.attributes.name,
          episode_type: song.attributes.episode_type,
          description: song.attributes.description,
          episode_number: song.attributes.episode_number,
          song_added: song.attributes.added.song_added,
          episode_content: song.attributes.episode_content,
        })),
      };
      this.setState({fullPlaylistInfo: transformedPlaylist})
      this.setState({isLoading: false});
    }
    else {
      this.setState({isLoading: false});
      this.setState({showFullPlaylist: true});
    }

  }

}
