import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path:string) => void;
  // Customizable Area End
}

interface Reply {
  "id": "5",
  "type": "contact_host_message",
  "attributes": {
    "sender_id": 79,
    "receiver_id": 79,
    "content": "Thank you for your reply!",
    "name": "User Name",
    "title": "Re: Initial Message Title",
    "description": "Further details...",
    "phone_number": "1234567890",
    "sent_at": "2024-11-26T10:07:10.577Z",
    "read": null
  }
}

interface S {
  // Customizable Area Start
  isVisible: boolean;
  showReplyBox: boolean;
  message: string;
  isMessageSent: boolean;
  openDialog: boolean;
  messageId: number | null;
  id: number | null;
  sender: string;
  subject: string;
  snippet: string;
  time: string;
  isRead: boolean;
  nameOfUser: string;
  phoneNumber_user: string;
  replies: Reply[];
  idToDelete: number | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReplyToController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  messageCallId: any;
  replyToMessageCallId: any;
  userDetailsApiCallId: any;
  deleteMessageApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";
    this.messageCallId = "";
    this.replyToMessageCallId = "";
    this.userDetailsApiCallId = "";

    this.state = {
      isVisible: false,
      showReplyBox: false,
      message: "",
      isMessageSent: false,
      openDialog: false,
      messageId: null,
      id: null,
      sender: "",
      subject: "",
      snippet: "",
      time: "",
      isRead: false,
      nameOfUser: "",
      phoneNumber_user: "",
      replies: [],
      idToDelete: null
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let messageId = await getStorageData("messageId");
    this.setState({ messageId });
    this.particularMessageApi();
    this.userDetails();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleMessagesApiResponse(from, message);
    this.handleResForuserSettingsApi(from, message);
    this.handleReplyToMessage(from, message);
    this.handleDeleteAndReplyMessage(from, message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleReply = () => {
    this.setState({ showReplyBox: true });
  }
  handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ message: event.target.value });
  };
  handleSend = () => {
    this.replyToMessageApi();
  }
  handleGoBack = () => {
    this.props.handleCommonPath("MyInbox")
  }
  handleOpenDialog = (id: number | null) => {
    this.setState({ openDialog: true, idToDelete: id })
  }
  handleCloseDialog = () => {
    this.setState({ openDialog: false })
  }
  handleDelete = () => {
    this.deleteMessageAndReplyApi();
  }
  particularMessageApi = async () => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token)
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.messageCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.messagesEndPoint}/${this.state.messageId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleMessagesApiResponse = async (from: string, message: Message) => {
    if (this.messageCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      try {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson) {
          if (responseJson.message) {
            const attributes = responseJson.message.data.attributes;
            this.setState({
              id: parseInt(responseJson.message.data.id),
              sender: attributes.name || '',
              subject: attributes.title || '',
              snippet: attributes.description || '',
              time: this.senttimeAgo(attributes.sent_at),
              isRead: attributes.read
            });
          }
          if (responseJson.replies) {
            let replyData: Reply[] = [];
            responseJson.replies.data.forEach((reply: Reply) => {
              replyData.push(reply)
            })
            this.setState({
              replies: replyData
            });
          }
        }
      } catch (error) {
        console.error('Error handling GET API response:', error);
      }
    }
  };

  userDetails = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleResForuserSettingsApi = async (from: string, message: Message) => {
    if (this.userDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson.data) {
        this.setState({ nameOfUser: `${responseJson.data.attributes.first_name} ${responseJson.data.attributes.last_name}`, phoneNumber_user: responseJson.data.attributes.phone_number })
      }
    }
  };

  replyToMessageApi = async () => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token),
      "content-type": "application/json"
    };
    let body = {
      "reply": {
        "content": "",
        "name": this.state.nameOfUser,
        "title": this.state.subject,
        "description": this.state.message,
        "phone_number": this.state.phoneNumber_user,
        "parent_message": this.state.messageId
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.replyToMessageCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.messagesEndPoint}/${this.state.messageId}/reply`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleReplyToMessage = async (from: string, message: Message) => {
    if (this.replyToMessageCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson.message) {
          this.setState({ isMessageSent: true, showReplyBox: false })
          this.particularMessageApi();
        }
    }
  };
  
  deleteMessageAndReplyApi = async () => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token)
    };
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMessageApiCallId = requestMessage1.messageId;
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.messagesEndPoint}/${this.state.idToDelete}`
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  };
  handleDeleteAndReplyMessage = async (from: string, message: Message) => {
    if (this.deleteMessageApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson.message) {
          this.setState({ openDialog: false });
          this.particularMessageApi();
        }
    }
  };
   senttimeAgo = (sentAt: string): string => {
    const timeDifference1 = new Date().getTime() - new Date(sentAt).getTime();
    const timeUnits1 = [
      { unit: 'second', factor: 1000 }, { unit: 'minute', factor: 60 }, { unit: 'hour', factor: 60 }, { unit: 'day', factor: 24 }, { unit: 'month', factor: 30 },
      { unit: 'year', factor: 12 },
    ];
    let timeValue1 = timeDifference1;
    for (let i = 0; i < timeUnits1.length; i++) {
      const nextFactor = i < timeUnits1.length - 1 ? timeUnits1[i + 1].factor : Infinity;
      if (timeValue1 < timeUnits1[i].factor * nextFactor) {
        const roundedValue = Math.floor(timeValue1 / timeUnits1[i].factor);
        return `${roundedValue} ${timeUnits1[i].unit}${roundedValue === 1 ? '' : 's'} ago`;
      }
      timeValue1 /= timeUnits1[i].factor;
    }
  
    return ''; // Fallback, though it shouldn't be reached.
  };
  
  // Customizable Area End
}
