import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React, { createRef } from 'react';
import {
  Button,
  Typography,
  Stack,
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path:string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  message: {
    id: number;
    sender: string;
    subject: string;
    snippet: string;
    time: string;
    isRead: boolean;
  }[];
  filteredData: {
    id: number;
    sender: string;
    subject: string;
    snippet: string;
    time: string;
    isRead: boolean;
  }[];
  selected: number[];
  openDialog: boolean;
  searchValue: string;
  currentPage: number,
  rowsPerPage: number,
  idToDelete: number | null
  idToDeleteArray: number[] | null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyInboxController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  getMessagesApiCallId: string = "";
  deleteApiCallId: string = "";
  bulkDeleteApiCallId: string = "";
  markAsReadApiCallId: string = "";
  scrollRef: any = createRef();
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      message: [],
      filteredData: [],
      selected: [],
      openDialog: false,
      searchValue: "",
      currentPage: 1,
      rowsPerPage: 20,
      idToDelete: null,
      idToDeleteArray: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getMessagesApi();
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleGetMessagesApiResponse(from, message);
    this.handleDeleteMessage(from, message);
    this.handleBulkDeleteMessage(from, message);
    this.handleBulkMarkAsReadMessage(from,message);
    // Customizable Area End
  }

  // Customizable Area Start
  getMessagesApi = async () => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token)
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMessagesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.messagesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetMessagesApiResponse = async (from: string, message: Message) => {
    if (this.getMessagesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      try {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson) {
          if (responseJson.data) {
            let responseData: { id: number; sender: string; subject: string; snippet: string; time: string; isRead: boolean; }[] = [];
            responseJson.data.forEach((item: any) => {
              const attributes = item.attributes;
              const data = {
                id: parseInt(item.id),
                sender: attributes.name || '',
                subject: attributes.title || '',
                snippet: attributes.description || '',
                time: this.timeAgo(attributes.sent_at),
                isRead: attributes.read
              };

              responseData.push(data);
            });
            this.setState({ message: responseData })
          } else if(responseJson.message === "Inbox is empty") {
            this.setState({ message: [] })
          }
        }
      } catch (error) {
        console.error('Error handling GET API response:', error);
      }
    }
  };
  handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = this.state.selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
  };
  handleReplyClick = (id: number) => {
    const messageForId = this.state.message.find(message => message.id === id);
    if (messageForId) {
      const updatedMessages = this.state.message.map(message => {
        if (message.id === id) {
          return { ...message, isRead: true }; 
        }
        return message; 
      });
      this.setState({ message: updatedMessages }, () => {
        this.props.handleCommonPath("ReplyTo");
        setStorageData("messageId", id);
      });
    }
  }
  handleGoBack = () => {
    this.props.handleCommonPath('Dashboard');
  }
  handleOpenDialog = (id: number[] | null, id1: number | null) => {
    this.setState({ openDialog: true, idToDeleteArray: id, idToDelete: id1 })
  }
  handleCloseDialog = () => {
    this.setState({ openDialog: false })
  }
  handleDelete = () => {
    if (this.state.idToDelete !== null) {
      this.deleteMessageApi();
    }
    else if (this.state.idToDeleteArray !== null) {
      this.deleteMessageBulkApi();
    }
  }
  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let searchValue = event.target.value;
    let filteredData = this.state.message.filter((item) => {
      return item.sender.toLowerCase().includes(searchValue.toLowerCase())
    });
    this.setState({ filteredData, searchValue })
  }

  getPaginatedData = () => {
    const { currentPage, rowsPerPage, searchValue, filteredData, message } = this.state;
    const data = searchValue.trim() !== "" ? filteredData : message;
    const totalDataLength = searchValue.trim() !== "" ? filteredData.length : message.length;
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + (rowsPerPage < totalDataLength ? rowsPerPage : totalDataLength);
    return data.slice(startIndex, endIndex);
  };

  handlePageChange = (newPage: number) => {
    this.setState({ currentPage: newPage });
  };

  renderPagination = () => {
    const { currentPage, rowsPerPage, searchValue, filteredData, message } = this.state;
    const totalDataLength = searchValue.trim() !== "" ? filteredData.length : message.length;
    const totalPages = Math.ceil(totalDataLength / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + (rowsPerPage < totalDataLength ? rowsPerPage : totalDataLength);

    return (
      <Stack direction="row" sx={{ justifyContent: 'end', margin: '16px 0', width: "129%" }}>
        <Button
          disabled={currentPage === 1}
          onClick={() => this.handlePageChange(currentPage - 1)}
        >
          <ChevronLeftIcon style={{ color: 'white' }} />
        </Button>
        <Typography variant="body2" sx={{ alignSelf: 'center', color: "white" }}>
          {startIndex + 1} - {endIndex} from {totalDataLength}
        </Typography>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => this.handlePageChange(currentPage + 1)}
        >
          <ChevronRightIcon style={{ color: "white" }} />
        </Button>
      </Stack>
    );
  };
  deleteMessageApi = async () => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token)
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.messagesEndPoint}/${this.state.idToDelete}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteMessage = async (from: string, message: Message) => {
    if (this.deleteApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      try {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson.message) {
          this.setState({ openDialog: false });
          this.getMessagesApi();
        }
      } catch (error) {
        console.error('Error handling GET API response:', error);
      }
    }
  };
  deleteMessageBulkApi = async () => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token),
      "content-type": "application/json"
    };
    let body = {
      "message_ids": this.state.idToDeleteArray
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bulkDeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteBulkApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleBulkDeleteMessage = async (from: string, message: Message) => {
    if (this.bulkDeleteApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      try {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson.message) {
          this.setState({ openDialog: false, idToDeleteArray: null, selected: [] });
          this.getMessagesApi();
        }
      } catch (error) {
        console.error('Error handling GET API response:', error);
      }
    }
  };
  timeAgo = (sentAt: string): string => {
    const timeDifference = new Date().getTime() - new Date(sentAt).getTime();
  
    const timeUnits = [
      { unit: 'second', factor: 1000 },
      { unit: 'minute', factor: 60 },
      { unit: 'hour', factor: 60 },
      { unit: 'day', factor: 24 },
      { unit: 'month', factor: 30 },
      { unit: 'year', factor: 12 },
    ];
  
    let timeValue = timeDifference;
    for (let i = 0; i < timeUnits.length; i++) {
      const nextFactor = i < timeUnits.length - 1 ? timeUnits[i + 1].factor : Infinity;
      if (timeValue < timeUnits[i].factor * nextFactor) {
        const roundedValue = Math.floor(timeValue / timeUnits[i].factor);
        return `${roundedValue} ${timeUnits[i].unit}${roundedValue === 1 ? '' : 's'} ago`;
      }
      timeValue /= timeUnits[i].factor;
    }
  
    return ''; // Fallback, though it shouldn't be reached.
  };
  
  bulkMarkAsReadApi = async(id: number[]) => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token),
      "content-type": "application/json"
    };
    let body = {
      "message_ids": id
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.markAsReadApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.markAsReadEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleBulkMarkAsReadMessage = async (from: string, message: Message) => {
    if (this.markAsReadApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      try {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson.message) {
          this.getMessagesApi();
        }
      } catch (error) {
        console.error('Error handling GET API response:', error);
      }
    }
  };
}   
  // Customizable Area End
