import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { createRef } from 'react'
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { next, prev } from "../../../blocks/dashboard/src/assets";
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void;
    handleAddAudio: (playable_type: string, playable_id: string) => void;
}

interface SS {
    id: any;
}

interface ArrowProps {
  onClick: () => void;
}


interface IPlaylistItem {
  id: string;
  title: string;
  type: string;
  image: string;
}

interface PlaylistDetail {
  cover_image: string | undefined;
  name: string;
  description: string;
  publish_date: string | null;
  episode_time: string | null;
  episode_content: string | null;
  song_added: boolean;
  id: string;
}

interface ISelectedPlaylist {
  title: string;
  songs: PlaylistDetail[];
}
  
interface S {
  openNewPlayListModal: boolean;
  playlistValue: string;
  selectedFile: File | null;
  filePreview: string | null;
  isLoading: boolean;
  playlistItem: IPlaylistItem[];
  selectedPlaylist: SelectedPlaylistCard;
  selectedPlaylistInfo: ISelectedPlaylist;
  editPlaylist: boolean;
  showDeletePlaylist: boolean;
  deleteLoading: boolean;
  deleteEpisodeLoad: boolean;
}

const initialSelectedPlaylist = {
  title: "",
  songs: [],
};

interface SelectedPlaylistCard {
  id: string;
  title: string;
  image: string;
}

export default class PlayListScreenController extends BlockComponent<Props, S, SS> {

  CreatePlaylistAPICallId: string = "";
  listPlaylistApiCallId: string = ""
  showPlaylistApiCallId: string = ""
  updatePlaylistApiCallId: string = ""
  deletePlaylistApiCallId: string = ""
  deleteEpisodeAPICallId = ""
  scrollRef: any = createRef();

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      openNewPlayListModal: false,
      playlistValue:"",
      selectedFile: null,
      filePreview: null,
      isLoading: false,
      playlistItem: [],
      selectedPlaylist: {
        id: "",
        title: "",
        image: ""
      },
      selectedPlaylistInfo : initialSelectedPlaylist,
      editPlaylist: false,
      showDeletePlaylist: false,
      deleteLoading: false,
      deleteEpisodeLoad: false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getlistPlaylist();
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
}


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(this.CreatePlaylistAPICallId === apiRequestCallId) {
        this.handleCreatePlaylistResponse(from, message)
      }

      if(this.listPlaylistApiCallId === apiRequestCallId) {
        this.handleListPlaylistResponse(from, message)
      }

      if(this.showPlaylistApiCallId === apiRequestCallId) {
        this.hanldeShowPlaylistResponse(from, message);
      }

      if(this.updatePlaylistApiCallId === apiRequestCallId) {
        this.handleEditPlaylistResponse(from, message)
      }

      if(this.deletePlaylistApiCallId === apiRequestCallId) {
        this.handleDeletePlaylistResponse(from, message)
      }

      if(this.deleteEpisodeAPICallId === apiRequestCallId) {
        this.handleDeleteEpisodeResponse(from, message)
      }
    }
  }

  handleFullList = () => {
    this.props.handleCommonPath("FullPlaylist");
  }

  handleAddShows = () => {
    if(this.state.selectedPlaylist.title === "Main Playlist") {
      this.props.handleCommonPath("AddPlayList");
    }
    // AddPlayList is for main playlist edit
    else {
      this.props.handleCommonPath("OtherPlayListEdit");
    }
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ playlistValue: e.target.value });
  };


  handleCreatePlaylist = async(label: string) => { 
    if(label === "create") {
      this.setState({ openNewPlayListModal: true });
    }
  }

  handleCloseCreatePlaylist = () => {
    this.setState({ openNewPlayListModal: false });
    this.setState({
      selectedFile: null,
      filePreview: null,
      playlistValue:""
    });
  }

  handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        this.setState({
          selectedFile: file,
          filePreview: URL.createObjectURL(file),
        });
      } else {
        this.setState({
          selectedFile: null,
          filePreview: null,
        });
      }
    }
  };

  handleUnselectImage = () => {
    this.setState({
      selectedFile: null,
      filePreview: null,
    });
  }

  handlePlaylistCreate = async() => {
    const getToken = await getStorageData('token')

    let token = getToken.slice(1, -1);

    const header = {
      token
    }

    if(this.state.selectedFile) {

      this.setState({isLoading: true});

      let formdata = new FormData();
      formdata.append("image", this.state.selectedFile);
      formdata.append("title", this.state.playlistValue);

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.CreatePlaylistAPICallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.songListApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
  }
  
  handleCreatePlaylistResponse = async (from: string, message: Message) => {

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId === this.CreatePlaylistAPICallId) {

      if(apiResponse?.errors?.length > 0) {
        this.showAlert("error",apiResponse?.errors[0]?.error?.[0])
        this.setState({isLoading: false});
      }
      if(apiResponse?.play_list_colection) {
        this.setState({isLoading: false});
        this.setState({openNewPlayListModal: false});
        await setStorageData("playlist_id", apiResponse?.play_list_colection?.data?.id);
        this.props.handleCommonPath("NewPlayListEdit");
      }
    }
  }

  getlistPlaylist = async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.listPlaylistApiCallId = requestMessage?.messageId

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.songListApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleListPlaylistResponse = async (from: string, message: Message) => {

    const mainPlaylistID = await getStorageData("main_playlist_id")

    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const extractedData = apiResponse.data.map((item: any) => ({
      id: item.id,
      title: item.attributes.title,
      type: item.type,
      image: item.attributes.image
    }))

    this.setState({playlistItem: extractedData})

    const mainPlaylist = extractedData.find((data:any) => data.title === "Main Playlist"); 

    const playlistDetail = {
      id: mainPlaylistID,
      title: mainPlaylist?.title,
      image: mainPlaylist?.image
    }
    this.handlePlaylistCardClick(playlistDetail)
    this.setState({selectedPlaylist: playlistDetail})
  }

  hanldeShowPlaylistResponse = async (from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const transformedPlaylist: ISelectedPlaylist = {
      title: apiResponse.playlist.data.attributes.title,
      songs: apiResponse.playlist.data.attributes.songs.data.map((song:any) => ({
        id: song.id,
        cover_image: song.attributes.cover,
        name: song.attributes.name,
        description: song.attributes.description,
        publish_date: song.attributes.publish_date.publish_date,
        episode_time: song.attributes.episode_time,
        episode_content: song.attributes.episode_content,
        song_added: song.attributes.added.song_added,
      })),
    };

    this.setState({selectedPlaylistInfo: transformedPlaylist})
  }

  getPlaylistSlickSettings() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    const { playlistItem } = this.state;
  
    return {
      dots: false,
      infinite: false, 
      speed: 800,
      slidesToShow: playlistItem.length,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: React.createElement(this.nextPlaylistArrow, { onClick: () => {}, innerWidth }),
      prevArrow: React.createElement(this.prevPlaylistArrow, { onClick: () => {}, innerWidth }),
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: playlistItem.length >= 6 ? 7 : playlistItem.length,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: playlistItem.length >= 5 ? 5 : playlistItem.length,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: playlistItem.length >= 4 ? 4 : playlistItem.length,
            slidesToScroll: Math.min(4, playlistItem.length),
          },
        },
      ],
    };
  }

  prevPlaylistArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img
        src={prev} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          left: innerWidth > 1440 ? "-5.5%" : "-7.5%",
          top: innerWidth > 1440 ? "60%" : "40%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Previous Arrow"
      />
    )
  }

  nextPlaylistArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img 
        src={next} 
        onClick={onClick}
        style={{
          position: 'absolute',
          right: innerWidth > 1440 ? "-5.5%" : "-7.5%",
          top: innerWidth > 1440 ? "60%" : "40%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1
        }} 
        alt="Next Arrow"
      />
    )
  }

  handleEditPlaylist = () => {
    this.setState({
      editPlaylist: true,
      openNewPlayListModal: true,
      playlistValue: this?.state?.selectedPlaylist?.title ?? "",
      filePreview: this?.state?.selectedPlaylist?.image ?? null,
    });
  }

  handlePlaylistCardClick = async(playlistData: SelectedPlaylistCard) => {
      const playlistDetail = {
        id: playlistData?.id,
        title: playlistData?.title,
        image: playlistData?.image
      }

      this.setState({selectedPlaylist: playlistDetail})

      await setStorageData("selected_playlist_id", playlistData?.id);
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.showPlaylistApiCallId = requestMessage?.messageId
  
      const token = await getStorageData('token')
  
      let cleanedToken = token.slice(1, -1);
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.songListApiEndPoint}/${playlistData?.id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: cleanedToken
        })
      );
  
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUpdatePlaylist = async() => {
    const getToken = await getStorageData('token')

    let token = getToken.slice(1, -1);

    const header = {
      token
    }

    if (this.state.editPlaylist) {
      this.setState({ isLoading: true });
    
      let formdata = new FormData();
      formdata.append("title", this.state.playlistValue); 
    
      if (this.state.selectedFile) {
        formdata.append("image", this.state.selectedFile); 
      }
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updatePlaylistApiCallId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.songListApiEndPoint}/${this.state.selectedPlaylist.id}`
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateMethodType
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
  }

  handleEditPlaylistResponse = async (from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiResponse?.play_list_colection?.data?.id) {
      this.setState({ isLoading: false }); 
      this.getlistPlaylist();
      this.setState({openNewPlayListModal: false, editPlaylist: false})
    }
    else {
      this.setState({ isLoading: false, editPlaylist: false });
      this.showAlert("error", "The playlist could not be updated")
    }
  }

  handleDeletePlaylist = () => {
    this.setState({showDeletePlaylist: true});
  }

  handleCloseDeletePlaylist = () => {
    this.setState({showDeletePlaylist: false});
  }

  handleConfirmDeletePlaylist = async() => {
    this.setState({deleteLoading: true});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deletePlaylistApiCallId = requestMessage?.messageId

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.songListApiEndPoint}/${this.state.selectedPlaylist.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteMethodtype
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeletePlaylistResponse = async (from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiResponse.message === "This Playlist deleted successfully") {
      this.setState({deleteLoading: false});
      this.setState({showDeletePlaylist: false});
      this.getlistPlaylist();
    }
    else {
      this.setState({deleteLoading: false});
    }
  }

  handleAddedBtn = async(id: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({deleteEpisodeLoad: true});

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);
  
    this.deleteEpisodeAPICallId = requestMessage?.messageId

    let formdata = new FormData();
    formdata.append("id", this.state.selectedPlaylist.id);
    formdata.append("song_id", id);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeSong}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodtype
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeleteEpisodeResponse = async(from: string, message: Message) => {
    let episodeResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(episodeResponse?.message === "Song removed successfully") {
      this.setState({deleteEpisodeLoad: false})
      this.handlePlaylistCardClick(this.state.selectedPlaylist)
    }
    else {
      this.setState({deleteEpisodeLoad: false})
      this.showAlert("Error", "Song Not Removed")
    }
  }
}