import React from "react";

// Customizable Area Start
import {
  bell,
  partner,
  envelope,
  logo,
  activeResident,
  resident,
  mic,
  activeDiscover,
  social,
  library,
  playlist,
  dashboard,
  activeDashboard,
  activeLibrary,
  activePlaylist,
  activeSocial,
  discover,
  build,
  activeBuild,
  engage,
  activeEngage,
  activeHostSettings,
  activeMonetize,
  activeMyChannel,
  myChannel,
  hostSettings,
  monetize,
  integrations,
  activeHost,
  becomeHostActive,
  nonActiveBecomeHost,
  nonActive_host
} from "./assets";
import {
  Avatar, IconButton, Box, Dialog,
  DialogContent, Button, styled
} from "@mui/material";
import { FmdGood, PersonOutline, Settings, SubdirectoryArrowRight, EventSeat, AllInbox, ModeEditRounded } from '@mui/icons-material'
import { ResolveCondition, handleNavigation } from "../../utilities/src/CustomHelpers";
import { ConditionJsx } from "../../utilities/src/ConditionJsx";
import CloseIcon from '@mui/icons-material/Close';
import AudioPlayerComponent from "../../audiolibrary/src/AudioPlayerComponent.web";
import PlayListScreen from "../../playlist/src/PlayListScreen.web";
import AudioLibrary from "../../audiolibrary/src/AudioLibrary.web";
import Discover from "../../categoriessubcategories/src/components/Discover.web";
import Build from '../../dashboard/src/Build.web';
import HostLogin from "../../email-account-login/src/HostLogin.web";
import HostMonetizationSetup from "../../email-account-login/src/HostMonetizationSetup.web";
import HostSettings from "../../settings2/src/HostSettings.web";
import Dashboard from "../../dashboard/src/DashboardTwo.web";
import AccountSettings from "../../settings2/src/AccountSettings.web";
import Catalogue from "../../catalogue/src/Catalogue.web";
import ActivityFeed from "../../activityfeed/src/ActivityFeed.web";
import HostCatalogue from "../../catalogue/src/HostCatalogue.web";
import AllCategories from "../../categoriessubcategories/src/components/AllCategories.web";
import SubCategories from "../../categoriessubcategories/src/components/SubCategories.web"
import HostPaymentPlan from "../../email-account-login/src/HostPaymentPlan.web";
import HostPaymentDetails from "../../email-account-login/src/HostPaymentDetails.web";
import HostSetupMethod from "../../email-account-login/src/HostSetupMethod.web";
import SetupPodcastProfileNoRssFeed from "../../email-account-login/src/SetupPodcastProfileNoRssFeed.web";
import SetUpProfileSecondScreenNoRss from "../../email-account-login/src/SetUpProfileSecondScreenNoRss.web";
import SetUpProfileThirdScreenNoRss from "../../email-account-login/src/SetUpProfileThirdScreenNoRss.web";
import ChooseTemplate from "../../email-account-login/src/ChooseTemplate.web";
import TemplateColorScheme from "../../email-account-login/src/TemplateColorScheme.web";
import TemplatePreviewWithLogo from "../../email-account-login/src/TemplatePreviewWithLogo.web";
import HostMonetizationChooseTemplate from "../../email-account-login/src/HostMonetizationChooseTemplate.web";
import HostMonetizationSponsoredContentPartnership from "../../email-account-login/src/HostMonetizationSponsoredContentPartnership.web";
import HostMonetizationTemplate from "../../email-account-login/src/HostMonetizationTemplate.web";
import HostMonetizationSponsoredPost from "../../email-account-login/src/HostMonetizationSponsoredPost.web";
import ChooseFeatureSubscriptionTier from "../../email-account-login/src/ChooseFeatureSubscriptionTier.web";
import HostMonetizationSellingDigitalProducts from "../../email-account-login/src/HostmonetizationSellingDigitalProducts.web";
import HostMonetizationOfferingConsultancy from "../../email-account-login/src/HostMonetizationOfferingConsultancy.web";
import HostMonetizationAffiliateMarketing from "../../email-account-login/src/HostMonetizationAffiliateMarketing.web";
import HostMonetizationPaidWebinar from "../../email-account-login/src/HostMonetizationPaidWebinar.web";
import HostMonetizationMerchandise from "../../email-account-login/src/HostMonetizationMerchandise.web";
import HostMonetizationOnlineAdvertising from "../../email-account-login/src/HostMonetizationOnlineAdvertising.web";
import HostCrowfundingResidentSupport from "../../email-account-login/src/HostCrowfundingResidentSupport.web";
import HostSetupRssFeed from "../../email-account-login/src/HostSetupRssFeed.web";
import SetupRssFeedPreview from "../../email-account-login/src/components/SetupRssFeedPreview.web";
import MyInbox from "../../contactus/src/MyInbox.web";
import ReplyTo from "../../contactus/src/ReplyTo.web";
import Settings2 from "../../settings2/src/Settings2.web";
import SettingsNotifications from "../../settings2/src/SettingsNotifications.web";
import EditProfile from "../../settings2/src/EditProfile.web";
import ChannelManagement from '../../dashboard/src/ChannelManagement.web';
import MediaManagement from '../../dashboard/src/MediaManagement.web';
import NewContent from "../../portfoliomanagement/src/NewContentTitle.web";
import NewContentAdds from "../../portfoliomanagement/src/NewContentAdds.web";
import MediaManagementNewContent from "../../portfoliomanagement/src/MediaManagementNewContent.web";
import Search from "../../search/src/Search.web";
import AddPlayList from "../../playlist/src/AddPlayList.web";
import FullPlayList from "../../playlist/src/FullPlayList.web";
import NewPlayListEdit from "../../playlist/src/NewPlayListEdit.web"
import OtherPlayListEdit from "../../playlist/src/OtherPlayListEdit.web"
import HostSetupRssFeedInfo from "../../../blocks/email-account-login/src/HostSetupRssFeedInfo.web";
import HostSetupRssEpisodeInfo from "../../../blocks/email-account-login/src/HostSetupRssEpisodeInfo.web";
import AllSearchList from "../../search/src/AllSearchList.web";
import TrendingPodcast from "../../categoriessubcategories/src/components/TrendingPodcast.web";
import TrendingEpisode from "../../categoriessubcategories/src/components/TrendingEpisode.web";
import ResetPassword from "../../settings2/src/ResetPassword.web";
import PodCastsFollowing from "../../audiolibrary/src/PodCastsFollowing.web";
import SavedEpisode from "../../audiolibrary/src/SavedEpisode.web";
import ChannelLibrary from "../../audiolibrary/src/ChannelLibrary.web";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isMenuOpen } = this.state;
    const accountDescription = "Settings Related To Your Personal Information And Account Credentials"
    

    return (
      <>
        <div style={{ display: "flex", height: "100%" }} >

          <div data-test-id="sidebar" style={styles.sidebarMain} onClick={(e) => e.stopPropagation()}>
            <div style={styles.sidebarWrapper} >
              <img src={mic} width="69px" height="70px" alt="logo" />
              <div style={{ padding: "35px 0" }} >
                <Avatar
                  data-test-id="avatar"
                  onClick={this.handleProfilePhotoClick}
                  sx={{ width: 76, height: 76, cursor: "pointer" }}
                  src={ResolveCondition(this.state.userResponse.attributes.profile_picture !== "", this.state.userResponse.attributes.profile_picture, "/broken-image.jpg")} />
              </div>
              <div style={styles.sidebarNavItems} >
                {this.state.isShowInNav === "Dashboard" &&
                  <>
                    <img
                      data-test-id="dashboard"
                      src={ResolveCondition(this.state.isShowInMeddile === 'Dashboard', activeDashboard, dashboard)}
                      onClick={() => this.handleSetPath("Dashboard")}
                      alt="dashboard icon"
                      style={{ cursor: "pointer" }} />
                    <img
                      data-test-id="playlist"
                      src={ResolveCondition(this.state.isShowInMeddile === 'Playlist', activePlaylist, playlist)}
                      onClick={() => this.handleSetPath("Playlist")}
                      alt="playlist icon"
                      style={{ cursor: "pointer" }} />
                    <img
                      data-test-id="library"
                      src={ResolveCondition(this.state.isShowInMeddile === 'AudioLibrary', activeLibrary, library)}
                      onClick={() => this.handleSetPath("AudioLibrary")}
                      alt="library icon"
                      style={{ cursor: "pointer" }} />
                    <img
                      data-test-id="discover"
                      src={ResolveCondition(this.state.isShowInMeddile === 'Discover', activeDiscover, discover)}
                      onClick={() => this.handleSetPath("Discover")}
                      alt="discover icon"
                      style={{ cursor: "pointer" }} />
                    <img
                      data-test-id="social"
                      src={ResolveCondition(this.state.isShowInMeddile === 'Social', activeSocial, social)}
                      onClick={() => this.handleSetPath("Social")}
                      alt="social icon"
                      style={{ cursor: "pointer" }} />
                  </>}
                {this.state.isShowInNav === "Build" &&
                  <>
                    <img
                      data-test-id="myChannel"
                      src={ResolveCondition(this.state.isShowInMeddile === 'MyChannel', activeMyChannel, myChannel)}
                      onClick={() => this.handleSetPath("MyChannel")}
                      alt="myChannel icon"
                      style={{ cursor: "pointer" }} />
                    <img
                      data-test-id="build"
                      src={ResolveCondition(this.state.isShowInMeddile === 'Build', activeBuild, build)}
                      onClick={() => this.handleSetPath("Build")}
                      alt="build icon"
                      style={{ cursor: "pointer" }} />
                    <img
                      data-test-id="engage"
                      src={ResolveCondition(this.state.isShowInMeddile === 'Engage', activeEngage, engage)}
                      onClick={() => this.handleSetPath("Engage")}
                      alt="engage icon"
                      style={{ cursor: "pointer" }} />
                    <img
                      data-test-id="monetize"
                      src={ResolveCondition(this.state.isShowInMeddile === 'HostMonetizationSetup', activeMonetize, monetize)}
                      onClick={() => this.handleSetPath("HostMonetizationSetup")}
                      alt="monetize icon"
                      style={{ cursor: "pointer" }} />
                    <img
                      data-test-id="integrations"
                      src={ResolveCondition(this.state.isShowInMeddile === 'Integrations', hostSettings, integrations)}
                      onClick={() => this.handleSetPath("Integrations")}
                      alt="integrations icon"
                      style={{ cursor: "pointer" }}
                    />
                    <img
                      data-test-id="hostSettings"
                      src={ResolveCondition(this.state.isShowInMeddile === 'HostSettings', activeHostSettings, hostSettings)}
                      onClick={() => this.handleSetPath("HostSettings")}
                      alt="hostSettings icon"
                      style={{ cursor: "pointer" }} />
                  </>}
              </div>
            </div>
          </div>

          <div style={{ width: "90%" }}>

            <div style={{ ...styles.main, backgroundColor: ResolveCondition((this.state.userStatus === 'host' && this.state.activeIcon === 'host'), "#1C1917", "#030303") }}>
              <div style={{ ...styles.iconsWrapper, width: "50%", paddingRight: "5px" }}>
                {(this.isShowEditLogoBtnForHost()) ? (
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <img
                      style={styles.logoImg}
                      src={this.state.channelData.attributes?.podcast_logo || logo}
                      alt="logo"
                    />
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: "-15px",
                        right: "-15px",
                        padding: '4px',
                      }}
                      onClick={() => this.handleIsShowEditHostLogoModal()}
                    >
                      <ModeEditRounded style={{ color: "red" }} />
                    </IconButton>
                  </div>
                ) :
                  (
                    <img
                      onClick={() => this.onLogoClick()}
                      style={styles.logoImg}
                      src={(this.props.newLogo ? this.props.newLogo : logo)}
                      alt="logo" />
                  )}

                <img
                  data-test-id="resident"
                  src={ResolveCondition(this.state.isShowInNav === "Dashboard", activeResident, resident)}
                  onClick={() => this.handleNavPath("Dashboard")}
                  alt="resident icon"
                  style={{ cursor: "pointer" }}
                />
                {this.state.isHost ? (
                  <img
                    data-test-id="host"
                    src={ResolveCondition((this.state.isShowInNav === "Build" ), activeHost, nonActive_host)}
                    onClick={() => this.handleNavPath("Build")}
                    alt="host icon"
                    style={{ cursor: "pointer", color: "white" }}
                  />
                ) : (
                  <img
                    data-test-id="host"
                    src={ResolveCondition(this.state.isShowInNav === "HostLogin", becomeHostActive, nonActiveBecomeHost)}
                    onClick={() => this.handleNavPath("HostLogin")}
                    alt="host icon"
                    style={{ cursor: "pointer" }}
                  />
                )}

                <img
                  data-test-id="partner"
                  src={partner}
                  onClick={() => this.setActiveIconAndRedirect('partner')}
                  alt="partner icon"
                  style={{ cursor: "pointer" }} />
              </div>
              <div style={{ ...styles.iconsWrapper, width: "50%" }}>
               
                <Search navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
                <div style={styles.notifyIcons}>
                  <img src={bell} />
                  <div onClick={() => this.setState({ openDropdown: !this.state.openDropdown })} data-test-id='envelopIcon'>
                    <img src={envelope} />
                  </div>
                </div>
              </div>
            </div>

            <MiddleContainer style={{ height: this.state.isShowAudio ? "70%" : "80%",}}>
              {
                this.state.isShowInMeddile === "AllSearchList" && <AllSearchList navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} handlePodcastId={this.handlePodcastId}/>
              }
              {
                this.state.isShowInMeddile === "MyInbox" && <MyInbox navigation={undefined} id={""} handleCommonPath={this.handleCommonPath}/>
              }
              {
                this.state.isShowInMeddile === "ReplyTo" && <ReplyTo navigation={undefined} id={""} handleCommonPath={this.handleCommonPath}/>
              }             
              {
                this.state.isShowInMeddile === "Dashboard" && <Dashboard navigation={undefined} handlePodcastId={this.handlePodcastId} handleAddAudio={this.handleAddAudio}/>
              }

              {
                this.state.isShowInMeddile === "Playlist" && <PlayListScreen navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} handleAddAudio={this.handleAddAudio}/>
              }

              {
                this.state.isShowInMeddile === "AudioLibrary" && <AudioLibrary navigation={undefined} screenId={""} handleCommonPath={this.handleCommonPath} handleAddAudio={this.handleAddAudio} handlePodcastId={this.handlePodcastId}/>
              }

              {
                this.state.isShowInMeddile === "Discover" && <Discover navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} handleAddAudio={this.handleAddAudio} handlePodcastId={this.handlePodcastId}/>
              }

              {
                this.state.isShowInMeddile === "Build" && <Build navigation={undefined} id={""} handleCommonPath={this.handleCommonPath}/>
              }

              {
                this.state.isShowInMeddile === "HostMonetizationSetup" && <HostMonetizationSetup navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostSettings" && <HostSettings navigation={undefined} id={""} />
              }

              {
                this.state.isShowInMeddile === "Social" && <ActivityFeed navigation={undefined} />
              }

              {
                this.state.isShowInMeddile === "MyChannel" && <HostCatalogue navigation={undefined} id={""} isShowEditHostLogoModal={this.state.isShowEditHostLogoModal} reRenderTheLogo={this.reRenderTheLogo} closeEditHostLogoModal={this.handleIsShowEditHostLogoModal}/>
              }

              {
                this.state.isShowInMeddile === "AccountSettings" &&   <AccountSettings navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} handleFetchProfile={this.userDetails}/>
              }
              {
                this.state.isShowInMeddile === "ResetPassword" && <ResetPassword navigation={this.props.navigation} id={""} handleCommonPath={this.handleCommonPath} />
              }
              {
                this.state.isShowInMeddile === "AllCategories" &&   <AllCategories handleCommonPath={this.handleCommonPath} navigation={undefined} id={""}/>
              }
              {
                this.state.isShowInMeddile === "SubCategories" &&   <SubCategories handleCommonPath={this.handleCommonPath} handlePodcastId={this.handlePodcastId} handleAddAudio={this.handleAddAudio} navigation={undefined} id={""}/>
              }
              {
                this.state.isShowInMeddile === "TrendingPodcast" &&   <TrendingPodcast handleCommonPath={this.handleCommonPath} navigation={undefined} id={""}/>
              }
              {
                this.state.isShowInMeddile === "TrendingEpisode" &&   <TrendingEpisode handleCommonPath={this.handleCommonPath} navigation={undefined} id={""}/>
              }

              {this.state.isShowInMeddile === "Catalogue" && <Catalogue navigation={undefined} id={""} handleAddAudio={this.handleAddAudio} />}

              {this.state.isShowInMeddile === "HostLogin" && <HostLogin navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />}

              {
                this.state.isShowInMeddile === "HostPaymentPlan" && <HostPaymentPlan navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostPaymentDetails" && <HostPaymentDetails navigation={undefined} id={""} hanldeParamState={this.hanldeParamState} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostSetupMethod" && <HostSetupMethod navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "SetupPodcastProfileNoRssFeed" && <SetupPodcastProfileNoRssFeed navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "SetUpProfileSecondScreenNoRss" && <SetUpProfileSecondScreenNoRss navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "SetUpProfileThirdScreenNoRss" && <SetUpProfileThirdScreenNoRss navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "ChooseTemplate" && <ChooseTemplate navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "TemplateColorScheme" && <TemplateColorScheme navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "TemplatePreviewWithLogo" && <TemplatePreviewWithLogo navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostMonetizationChooseTemplate" && <HostMonetizationChooseTemplate navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "SponsoredContentPartnership" && <HostMonetizationSponsoredContentPartnership navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostMonetizationTemplate" && <HostMonetizationTemplate navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostMonetizationSponsoredPost" && <HostMonetizationSponsoredPost navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "ChooseFeatureSubscriptionTier" && <ChooseFeatureSubscriptionTier navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostMonetizationSellingDigitalProducts" && <HostMonetizationSellingDigitalProducts navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostMonetizationOfferingConsultancy" && <HostMonetizationOfferingConsultancy navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostMonetizationAffiliateMarketing" && <HostMonetizationAffiliateMarketing navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostMonetizationPaidWebinar" && <HostMonetizationPaidWebinar navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostMonetizationMerchandise" && <HostMonetizationMerchandise navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostMonetizationOnlineAdvertising" && <HostMonetizationOnlineAdvertising navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostCrowfundingResidentSupport" && <HostCrowfundingResidentSupport navigation={undefined} id={""} handleBuild={this.handleBuild} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostSetupRssFeed" && <HostSetupRssFeed navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostSetupRssFeedInfo" && <HostSetupRssFeedInfo navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "HostSetupRssEpisodeInfo" && <HostSetupRssEpisodeInfo navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "SetupRssFeedPreview" && <SetupRssFeedPreview navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "Settings2" && <Settings2 navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} handleCommonPathNav={this.handleCommonPathNav}/>
              }

              {
                this.state.isShowInMeddile === "EditProfile" && <EditProfile navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} handleFetchProfile={this.userDetails}/>
              }

              {
                this.state.isShowInMeddile === "SettingsNotifications" && <SettingsNotifications navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "ChannelManagement" && <ChannelManagement navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "MediaManagement" && <MediaManagement navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "NewContent" && <NewContent navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "NewContentAdds" && <NewContentAdds navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {
                this.state.isShowInMeddile === "MediaManagementNewContent" && <MediaManagementNewContent navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} />
              }

              {this.state.isShowInMeddile === "AddPlayList" && <AddPlayList navigation={undefined} id={""} handleCommonPath={this.handleCommonPath}/>}

              {this.state.isShowInMeddile === "FullPlaylist" && <FullPlayList navigation={undefined} id={""} handleCommonPath={this.handleCommonPath} handleAddAudio={this.handleAddAudio}/>}

              {this.state.isShowInMeddile === "NewPlayListEdit" && <NewPlayListEdit navigation={undefined} id={""} handleCommonPath={this.handleCommonPath}/>}

              {this.state.isShowInMeddile === "OtherPlayListEdit" && <OtherPlayListEdit navigation={undefined} id={""} handleCommonPath={this.handleCommonPath}/>}

              {this.state.isShowInMeddile === "PodCastFollowing" && <PodCastsFollowing navigation={undefined} handleCommonPath={this.handleCommonPath} screenId={""}/>}

              {this.state.isShowInMeddile === "SavedEpisode" && <SavedEpisode navigation={undefined} handleCommonPath={this.handleCommonPath} handleAddAudio={this.handleAddAudio} screenId={""}/>}
              
              {this.state.isShowInMeddile === "ChannelLibrary" && <ChannelLibrary navigation={undefined} handleCommonPath={this.handleCommonPath} handleAddAudio={this.handleAddAudio} screenId={""}/>}
              
            </MiddleContainer>

            {
              this.state.isShowAudio && <AudioPlayerComponent
                navigation={this.props.navigation}
                id=""
                handleLastAudioPlay={this.handleLastAudioPlay}
                audioTracks={this.state.audioTracks}
                isPlaying={this.state.isPlaying}
              />
            }

          </div>

          {isMenuOpen && (
            <div style={styles.sidebarModalOverlay} data-test-id="menu" onClick={() => this.setState({ isMenuOpen: false })}>
              <div style={styles.sidebarModal} onClick={(e) => e.stopPropagation()}>
                <div style={styles.sidebarInfo}>
                  <Avatar
                    sx={{ width: 132, height: 132, border: "4px solid #030303" }}
                    src={ResolveCondition(this.state.userResponse.attributes.profile_picture !== "", this.state.userResponse.attributes.profile_picture, "/broken-image.jpg")} />
                  <div style={styles.sidebarDetails}>
                    <span style={styles.sidebarName}> {this.state.userResponse.attributes.first_name} {this.state.userResponse.attributes.last_name}</span>
                    <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                      <FmdGood sx={{ color: "#fff" }} fontSize="small" />
                      <span style={styles.sidebarLocation}>{this.state.userResponse.attributes.user_location}</span>
                    </div>
                  </div>
                  {ConditionJsx(this.state.userResponse.attributes.bio !== null, <div style={styles.sidebarDescription}>{this.state.userResponse.attributes.bio}</div>, <div></div>)}
                </div>
                <div style={styles.sidebarSettings}>
                  <span style={styles.sidebarHeader}>Settings</span>
                  <div style={{ ...styles.sidebarAccount, backgroundColor: ResolveCondition(this.state.settingsSideBarActiveIcon === "AccountSettings", "#030303", "#44403C"), cursor: "pointer" }} onClick={() => this.handleIsOpenMenuItem("AccountSettings")} data-test-id="account-button">
                    <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                      <PersonOutline />
                      <span style={styles.sidebarTitle}>Account</span>
                    </div>
                    <span style={styles.sidebarSettingsDescription}>{accountDescription}</span>
                  </div>
                  <div style={{ ...styles.sidebarAccount, backgroundColor: ResolveCondition(this.state.settingsSideBarActiveIcon === "Settings2", "#030303", "#44403C"), marginTop: '10px', cursor: "pointer" }} onClick={() => this.handleIsOpenMenuItem("Settings2")} data-test-id="settings-button">
                    <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                      <Settings />
                      <span style={styles.sidebarTitle} data-test-id="Settings" onClick={() => { handleNavigation("Settings2") }}>Settings</span>
                    </div>
                    <span style={styles.sidebarSettingsDescription}>Access To Configurations</span>
                  </div>
                  <div style={{ ...styles.sidebarAccount, backgroundColor: this.state.settingsSideBarActiveIcon === "Logout" ? "#030303" : "#44403C" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                      <SubdirectoryArrowRight />
                      <span style={styles.sidebarTitle} data-test-id="SubdirectoryArrowRight" onClick={() => this.setState({ isModalOpen: true })}>Log out</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.openDropdown && (
          <div style={{ width: "100%", height: "8rem", position: "absolute", top: "15%" }}>
            <p
              data-test-id="contactMayor"
              onClick={(e) => {
                const target = e.target as HTMLElement;
                window.location.href = 'https://podcasttown.zohodesk.com/portal/en/newticket';
                target.style.backgroundColor = '#FF0807';
              }}
              style={{
                cursor: 'pointer', color: '#fff', transition: 'color 0.3s', borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px",
                backgroundColor: '#44403C',
                fontFamily: 'Nexa-Heavy',
                border: '1px solid white',
                padding: '1%',
                width: '14%',
                height: '3rem',
                marginLeft: '81%',
                marginTop: '-1%',
                borderBottom: 'none'
              }}
              onMouseEnter={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = '#FF0807';
              }}
              onMouseLeave={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = '#44403C';
              }}
            >
              <IconButton><EventSeat style={{ color: "#fff" }} /></IconButton> Contact The Mayor's Office
            </p>
            <p
              data-test-id="myInbox"
              onClick={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = '#FF0807';
                this.handleCommonPath('MyInbox')
              }}
              style={{
                cursor: 'pointer', color: '#fff', transition: 'color 0.3s', borderBottomRightRadius: "8px",
                borderBottomLeftRadius: "8px",
                backgroundColor: '#44403C',
                fontFamily: 'Nexa-Heavy',
                border: '1px solid white',
                padding: '1%',
                width: '14%',
                height: '2rem',
                marginLeft: '81%',
                marginTop: '-2%',
                borderTop: 'none'
              }}
              onMouseEnter={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = '#FF0807';
              }}
              onMouseLeave={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = '#44403C';
              }}
            ><IconButton><AllInbox style={{ color: "#fff" }} /></IconButton>My Inbox</p>
          </div>
        )}
        <Dialog
          open={this.state.isModalOpen}
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              borderRadius: "8px 8px 32px 8px",
              width: "550px",
              height: "250px",
            },
          }}
        >
          <DialogContent
            style={{ backgroundColor: "#44403C", color: "white", padding: 0, }}

          >
            <IconButton
              data-test-id="onCloseModal"
              id="categoriesModal"
              onClick={this.handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white'
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box id="categoriesModal" style={{ paddingTop: "35px" }}>
              <h2 style={{ textAlign: "center", fontFamily: "Nexa-Heavy" }}>Are You Sure You Want To Logout?</h2>
            </Box>
            <Box style={{
              width: "498px", height: "104px", borderTop: "1px solid white", display: "flex",
              alignItems: "center", marginTop: "25px",
              justifyContent: "flex-end",
            }}>

              <Button style={{
                height: "56px",
                width: "152px",
                borderRadius: "8px",
                backgroundColor: "black",
                textTransform: "capitalize",
                color: "white",
                fontWeight: 900,
                fontSize: "14px",
                margin: "10px"
              }} onClick={this.handleCloseModal}>Cancel</Button>
              <Button style={{
                height: "56px",
                width: "152px",
                borderRadius: "8px",
                backgroundColor: "#FF0807",
                textTransform: "capitalize",
                color: "white",
                fontWeight: 900,
                fontSize: "14px",

                margin: "10px"
              }} onClick={() => this.handleLogOut()}>Logout</Button>

            </Box>

          </DialogContent>
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MiddleContainer = styled("div")({
    overflowY: "scroll" as const,
    scrollbarWidth: "thin",
    scrollbarColor: "red gray",
    "&::-webkit-scrollbar": {
        width: "12px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "red", 
        borderRadius: "10px", 
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "gray", 
      },
      "&::-webkit-scrollbar-button": {
        display: "none", 
      } as const,
})

const styles = {
  main: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-around",
    gap: "65px",
    height: "10%",    
    padding: "30px 50px"
  },
  iconsWrapper: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center" as const,
    flex: 1,
    position: 'relative' as const
  },
  searchInput: {
    padding: "10px 8px 10px 8px",
    border: "1px solid white",
    borderRadius: "98px",
    backgroundColor: "#030303",
    color: "white",
    outline: "none",
    flex: 7,
    paddingLeft: "35px",
  },
  notifyIcons: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  logoImg: {
    width: "148px",
    height: "65px",
    cursor: "pointer"
  },
  container: {
    display: "flex",
    flexDirection: "row" as const,
  },
  sidebar: {
    width: "135px",
    backgroundColor: "#030303",
    borderRight: "1px solid #E2E8F0",
    height: "100vh",
  },
  sidebarMain: {
    backgroundColor: "#030303",
    borderRight: "1px solid #E2E8F0",
    width: "10%",
    height: "100vh"
  },
  sidebarWrapper: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    alignItems: "center" as const,
    paddingTop: "45px",
  } as React.CSSProperties,
  sidebarNavItems: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center" as const,
    gap: 20
  } as React.CSSProperties,
  sidebarModalOverlay: {
    position: 'fixed',
    top: 0,
    left: '136px',
    width: 'calc(100% - 136px)',
    height: '90vh',
    backdropFilter: 'blur(4px)' as const,
    zIndex: 100 as const,
    display: 'flex',
    justifyContent: 'start',
  } as React.CSSProperties,
  sidebarModal: {
    display: "flex",
    flexDirection: "column",
    width: "362px",
    backgroundColor: '#44403C',
    borderBottomRightRadius: "64px" as const,
    borderLeft: "1px solid #030303" as const
  } as React.CSSProperties,
  sidebarInfo: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#312F2D",
    borderBottomRightRadius: "32px",
    paddingTop: typeof window !== 'undefined' && window.innerHeight > 900 ? "50px" : "5px",
    paddingBottom: "10px"
  } as React.CSSProperties,
  sidebarDetails: {
    display: "flex",
    alignItems: 'center',
    color: "#fff",
    flexDirection: "column",
    padding: "0 20px"
  } as React.CSSProperties,
  sidebarLocation: {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
  } as React.CSSProperties,
  sidebarDescription: {
    width: "327px",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 900,
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    display: "block",
    lineHeight: "1.2",
    maxHeight: "3.1rem",
    padding: 0,
    margin: 0,
    textAlign: "center"
  } as React.CSSProperties,
  sidebarName: {
    textAlign: "center",
    width: "265px",
    fontWeight: 900,
    fontSize: typeof window !== 'undefined' && window.innerHeight < 900 ? "20px" : "30px",
    fontFamily: "Poppins",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  } as React.CSSProperties,
  sidebarSettings: {
    display: "flex",
    flexDirection: "column",
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: 900,
  } as React.CSSProperties,
  sidebarHeader: {
    backgroundColor: "#312F2D",
    margin: "10px 0",
    padding: "10px 20px",
    fontSize: typeof window !== 'undefined' && window.innerHeight < 900 ? "16px" : "20px",
  } as React.CSSProperties,
  sidebarAccount: {
    display: "flex",
    flexDirection: "column",
    margin: "0 20px",
    padding: "15px 15px",
    borderRadius: "8px"
  } as React.CSSProperties,
  sidebarTitle: {
    fontSize: typeof window !== 'undefined' && window.innerHeight < 900 ? "15px" : "18px",
    cursor: "pointer"
  },
  sidebarSettingsDescription: {
    fontSize: "14px",
    width: "250px",
    paddingTop: "10px",
    paddingLeft: "25px"
  } as React.CSSProperties,
  mailDropdown: {
    borderRadius: '8px',
    backgroundColor: '#44403C',
    color: '#fff',
    fontFamily: 'Nexa-Regular',
    border: '2px solid white',
    padding: '1%',
    width: '14%',
    height: '7rem',
    marginLeft: '81%',
    marginTop: '-1%'
  }
};


// Customizable Area End
