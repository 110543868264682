import React, { Component }  from "react";
import { Box, Typography, styled} from "@mui/material";
import Slider from "react-slick";

import { next, prev } from "../../blocks/dashboard/src/assets";
const MIN_WINDOW_HEIGHT = 900


interface ArrowProps {
  onClick: () => void;
}

export default class PodcastFollowPost extends Component<{suggestedPodcasts: any, handlePodcastId: (id: string) => void}> {
    constructor(props: any) {
      super(props);
     
    }

    getSuggestedSlickSettings(){
      const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  const {suggestedPodcasts} = this.props
      return(
         {
          dots: true,
          infinite: true,
          speed: 800,
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 0,
          nextArrow: React.createElement(this.nextSuggestedArrow, { onClick: () => {}, innerWidth }),
          prevArrow: React.createElement(this.prevSuggestedArrow, { onClick: () => {}, innerWidth }),
          responsive: [
            {
              breakpoint: 1920,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1700, 
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
          ]
         }
      )
    }
    nextSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
      const { onClick, innerWidth } = props
  
      return(
        <img 
          data-test-id="nextSuggestedArrow"
          src={next} 
          onClick={onClick}
          style={{
            position: 'absolute',
            right: "-5.5%",
            top: "45%",
            transform: 'translateY(-45%)',
            cursor: 'pointer',
            zIndex: 1
          }} 
          alt="Next Arrow"
        />
      )
    }
    prevSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
      const { onClick, innerWidth } = props
  
      return(
        <img
          data-test-id="preSuggestedArrow"
          src={prev} 
          onClick={onClick} 
          style={{
            position: 'absolute',
            left: "-5.5%",
            top: "45%",
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            zIndex: 1,
          }} 
          alt="Previous Arrow"
        />
      )
    }
 
    render() {
  
      const { suggestedPodcasts } = this.props;
      return (
        <>
           
            <StyledSlider {...this.getSuggestedSlickSettings()}>
              {suggestedPodcasts.map((item: any) => (
                  <div key={item.id}>
                     <div style={webStyles.suggestedImgBody}>
                    <Box  sx={{...webStyles.image, height: "223px"}}>
                        <Box
                          sx={{...webStyles.followingText,
                            backgroundColor: item.attributes.follower.following == "Follow" ? "#FF0807" : "#030303", cursor: "pointer"
                          }}>
                          <Typography style={{ justifySelf: "flex-end", fontWeight:"bold" }}>{item.attributes.follower.following}</Typography>
                        </Box>
                        <img style={{ ...webStyles.image, height: "223px" }} src={item.attributes.cover_image} alt={item.attributes.name} onClick={()=>this.props.handlePodcastId(item.id)}/>
                      </Box>
                        
                      <div style={webStyles.description}>
                        <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                          {item.attributes.name.toUpperCase()}
                        </Typography>
                        <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                          {item.attributes.description}
                        </Typography>
                      </div>
                    </div> 
                  </div>
                ))}
              
            </StyledSlider>
         
        </>
  
      );
   
    }
  }
  
  const webStyles = {
 
  image: {
    objectFit: "cover" as const,
    width: '100%',
    height: typeof window !== 'undefined' && window.innerWidth > 1440 ? "397px" : "370px",
    display: "flex",
    margin: "0 auto",
  },
   
    title: {
      fontWeight: 900,
      fontSize: "30px",
      color: '#fff',
      marginBottom: "10px"
    },
    episode: {
      fontSize: '14px',
      color: "#fff",
      lineHeight: '22px',
      fontWeight: 600
    },
    sponsoredImgBody: {
      width: '100%',
      height: "370px",
      position: 'relative' as const
    },
    sponsoredButton: {
      color: "#fff",
      backgroundColor: "#FF0807",
      padding: '10px 16px',
      cursor: 'pointer',
      border: 'none',
      borderRadius: '8px',
      fontWeight: 900,
      fontSize: "14px"
    },
    suggestedImgBody: {
      width: '227px',
      height: "223px",
      position: 'relative' as const
    },
  
    boxContain: {
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },
  
    label: {
      display: "flex",
      fontWeight: "webStyles",
      fontSize: "18px",
      color: "white",
      alignItem: "center",
      justifyContent: "center",
    },
  
    description: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: "76px",
      backgroundColor: "#44403c",
      borderBottomLeftRadius: "16px",
      borderBottomRightRadius: "16px",
      padding: "16px"
    } as React.CSSProperties,
  
    "& .mainContainer": {
      color: "white",
    },
    dividerImg: {
      marginRight: "24px",
      marginLeft: "16px",
      height: "289px"
    },
    boxContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItem: "center",
  
    },
    categoriesImg: {
      height: "40%",
      width: "40%"
    },
    viewAllCategoriesImg: {
      height: "80px",
      width: "80px",
      selfAlign: "center",
    },
    favCategriesbox: {
      background: "#312F2D",
          height: "223px",
          width: "225px",
          borderRadius: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          margin: "8px"
    },
 
    followingText: {
      top: "15px",
      right: "15px",
      position: "absolute",
      color: "white",
      borderRadius: "8px",
      padding: "8px"
  }
  };
  const StyledSlider = styled(Slider)`
      .slick-dots {
        bottom: 40px;
      }
      .slick-dots li {
        margin: 0px !important;
      }
    `;