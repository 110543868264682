import React from "react";
import { Box, MenuItem, Select } from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web"
import Footer from "../../navigationmenu/src/Footer.web"; 
import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import PodCastsFollowingController, {
  Props
} from "./PodCastsFollowingController";
import ImageCard from "./components/ImageCard.web";
import AudioPlayerComponent from "./AudioPlayerComponent.web";
const MIN_WINDOW_HEIGHT = 900


const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding:"20px 10px",
          backgroundColor: "#44403C",
          fontSize: "20px",
          color: "white",
          fontFamily: "Nexa-Heavy",
          "&.Mui-selected": {
            backgroundColor: "red !important", 
            padding:"20px 10px",
            color: "white", 
            borderBottom: "1px solid white"
          },
          "&:hover": {
            backgroundColor: "#44403C", // Optional hover effect
          }
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: "0px !important", 
        },
      },
    },
  },
});

const PodwebStyles = {
  container: {
    padding: "20px 50px", 
    marginBottom: "2%",
  } as const,
}

export default class PodCastsFollowing extends PodCastsFollowingController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    return (
      <>
         <div style={PodwebStyles.container}>
          <Box mb={3}> 
  
          <ThemeProvider theme={theme}>
          <Select
          onChange={this.handleChange}
          value={this.state.podcastName}
          sx={{
            border: 'none', 
            borderRadius: '8px',
            fontSize: "28px",
            minWidth: 420, 
            color: "white",
            fontFamily: "Nexa-Heavy",
            '& .MuiSelect-select': {
              paddingRight: '48px !important', 
            },
            '& .MuiSelect-icon': {
              color: 'black', 
              width: "33px",
              height: "30px",
              background: "white",
              borderRadius: "8px",
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            },
          }}
        >
        <MenuItem  value={"Podcasts I'm Following"}>Podcasts I'm Following</MenuItem >
        <MenuItem  value={"Podcasts I'm Subscribed To"}>Podcasts I'm Subscribed To</MenuItem >
        </Select>
        </ThemeProvider>
          <Box display={"flex"} gap={5} flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"center"}>
            {
              this.state.followingPodCasts.map((data, index) => (
                <ImageCard
                key={index} 
                imageUrl={data.imageUrl}
                songName={data.songName}
                artist={data.artist}
                following={data.following}
                onUnfollow={() => {}}
                onNavigate={() => {}}
              />
              ))
            }
          </Box>
          </Box>
          <Footer navigation={this.props.navigation} id={"1"} />
          </div>  
          {/* <div style={{
           paddingLeft: "calc(6% + 135px)"
           }}>
          <AudioPlayerComponent navigation={this.props.navigation} id="" />
        </div> */}
        </>
    );
  }
}
