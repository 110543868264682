import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import FullPlayListController, { Props } from "./FullPlayListController.web";
import React from "react";
import { Box, Typography, Button, Grid, CircularProgress } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { micImg } from "./assets";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import AudioPlayerComponent from "../../audiolibrary/src/AudioPlayerComponent.web";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

const MIN_WINDOW_HEIGHT = 900


export default class FullPlayList extends FullPlayListController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                {
                this.state.isLoading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <CircularProgress /> 
                </div>
                ) : 
                 this.state.showFullPlaylist ? (
                    <Button
                      sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "#FF0807",
                        padding: "10px 16px",
                        "&:hover": {
                          background: "#FF0807",
                        },
                      }}
                      variant="contained"
                      startIcon={<PlaylistAddIcon />}
                    >
                      Add Shows To My Playlist
                    </Button> 
                ) :
                 <div style={webStyles.container}>
                    <Box display={"flex"} gap={2} alignItems={"center"} mb={2}>
                        <Box
                            bgcolor="#44403C"
                            padding={"8px 14px"}
                            borderRadius={1}
                            onClick={() => this.handleBack()}
                            data-test-id="back"
                        >
                            <ArrowBackIosIcon
                                sx={{ color: "white", marginRight: "-12px" }}
                            />
                        </Box>
                        <Typography sx={webStyles.title}>Playlist</Typography>
                    </Box>
                    <Box display={"flex"} gap={2} mt={2}>
                        <img src={this.state.fullPlaylistInfo.image} style={{ borderRadius: "12px", objectFit:"cover" }} height="182px" width="182px" alt="background_img" />
                        <Box display={"flex"} flexDirection={"column"} gap={2}>
                            <Typography sx={{ ...webStyles.title, fontSize: "48px", textTransform: "uppercase", lineHeight:"56px" }} >{this.state.fullPlaylistInfo.title}</Typography>
                            <Typography sx={webStyles.title}>{`${this.state.fullPlaylistInfo.songs.length} episodes`}</Typography>
                            <Button
                                sx={{
                                    ...buttonStyles,
                                    border: "none",
                                    color: "white",
                                    background: "#FF0807",
                                    padding: "10px 16px",
                                    
                                    "&:hover": {
                                        background: "#FF0807",
                                    }
                                }}
                                variant="contained"
                                startIcon={<PlaylistAddIcon />}
                                onClick={this.handleNavigateToPlaylist}
                            >
                                Add Shows To My Playlist
                            </Button>
                        </Box>
                    </Box>
                    <Box mt={8}>
                        <Grid container spacing={2}>
                            <Grid item sm={8} xs={8} md={9}>
                            {this.state.fullPlaylistInfo.songs.length === 0 ? (
                                <Typography
                                    sx={{
                                        ...webStyles.title,
                                        textAlign: "center",
                                        color: "white",
                                        marginTop: "20px",
                                    }}
                                >
                                    No Episodes found
                                </Typography>
                            ) : (
                                this.state.fullPlaylistInfo.songs.map((data, index) => (
                                    <Grid key={index} bgcolor={"#44403C"} container padding={"32px 24px"} mb={2}>
                                        <Grid item sm={2} lg={2} md={2} color={"white"}>
                                            <img
                                                src={data.cover_image}
                                                width={"118px"}
                                                height={"118px"}
                                                alt="Thumbnail"
                                                style={{objectFit:"cover"}}
                                            />
                                        </Grid>
                                        <Grid item sm={7} lg={7} md={7} color={"white"}>
                                            <Typography style={{ ...webStyles.title, fontSize: "18px" }}>
                                                {data.name}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    ...webStyles.title,
                                                    lineHeight: "10px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {data.episode_type}
                                            </Typography>
                                            <Typography style={webStyles.description}>{data.description}</Typography>
                                        </Grid>
                                        <Grid item sm={3} lg={3} md={3} color={"white"}>
                                            <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
                                                <Box gap={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Typography style={{ ...webStyles.title, fontSize: "14px" }}>
                                                        Episode {data.episode_number}
                                                    </Typography>
                                                    <Button
                                                        sx={{
                                                            ...buttonStyles,
                                                            width: "150px",
                                                            fontSize: "10px",
                                                            border: "none",
                                                            color: "white",
                                                            background: "#FF0807",
                                                            padding: "4px 16px",
                                                            "&:hover": {
                                                                background: "#FF0807",
                                                            },
                                                        }}
                                                        variant="contained"
                                                        startIcon={<FavoriteBorderIcon />}
                                                    >
                                                        Save For Later
                                                    </Button>
                                                </Box>
                                                <Button
                                                    sx={{
                                                        ...buttonStyles,
                                                        width: "150px",
                                                        fontSize: "10px",
                                                        border: "none",
                                                        color: "white",
                                                        background: "#030303",
                                                        padding: "4px 16px",
                                                        marginTop: "8px",
                                                        "&:hover": {
                                                            background: "#030303",
                                                            color: "white"
                                                        }
                                                    }}
                                                    variant="contained"
                                                    startIcon={<PlaylistAddCheckIcon />}
                                                >
                                                    Added
                                                </Button>
                                                <Box mt={3} onClick={ () => this.props.handleAddAudio("BxBlockLogin::Episode", data.episodeId)}>
                                                    <PlayCircleFilledWhiteIcon
                                                        sx={{ color: "red", fontSize: "2rem" }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )))
                            }
                            </Grid>
                            <Grid item sm={4} xs={4} md={3}>
                                <Box display={"flex"} flexDirection={"column"} gap={2}>
                                    <img src="https://s3-alpha-sig.figma.com/img/7646/707b/fbfbcd052cefc13ff1a07833c4e71a86?Expires=1733702400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jhUjBAilt5uUcTIXvUjixIbj1fS0gqbcyG62EF60~AeGDjIrZvFChru3tRz7Cnyh4td0vnB8R-qFIxxNcchwj~3RGpcBhMpzz4y8oeaz50rtV2R3ZTfwiqyl6w47AGUwuwWeYw8MYRWY~nCslEonDARFmwarVTlzuVkWrTpjckNAFx6g85jKTAhK2ESSYJEYCZBvvP57LQe3pC2nX6-lHXtlgqA5ypy2AMfm0dXKFVVQhM~SxcRDL8Osyi6kkq8aTGBzUDn3jd8QokliiA953Ghq918nXsur5eHWjU5cLbOG2V93-RZnxzxtN2~2xbJFJhqvarj2ngr8o~6EjTsShw__"
                                        width={"371px"}
                                        height={"242px"}
                                        style={{ objectFit: "cover", borderRadius: "13px" }}
                                    />
                                    <img src="https://s3-alpha-sig.figma.com/img/b5a3/5cbc/0b8d207a7aae44fb0c222e3effd6bec9?Expires=1733702400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Cp2pB0kUQcwj7hLHAmNW~VXgE9CU0jaj7wQgM1DlnY~-15svLS3C1RDPPkYi1bjc~WcRzjS7~mLbmOrIYX4tv~yyDNhBlp~tiANeHHM8TkQblgJspV2xdRAvljf1njzbZYsdpdqtMlOLtx2bTs7JA0kBaUqOAh0HDN82ra6EtEj76csbongtyrq7oD4laujJPXQxvmey6h3yG8Kp21CUbA66QzdLUfYcL1fkd33lxFof0M9jKIhTh9ljdICO5IjZPZTpA0uZK77Sj5eziAgn3bvMayogewEMc8TKRsYbxXcR2ZYfpCvrRM0i-zXqeNdjT4HFepmM4ULHZ170NDz~5Q__"
                                        width={"371px"}
                                        height={"275px"}
                                        style={{ objectFit: "cover", borderRadius: "13px" }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Footer navigation={this.props.navigation} id={"1"} />
                </div>
                }
            </>
        )
    }
}

const webStyles = {
    container: {
        padding:"20px 50px",
        overflowX: "clip"
      } as const,
    title: {
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: "40px",
        letter: "-0.5%",
        color: "#fff",
        fontFamily: "Nexa-Heavy"
    },
    description: {
        fontFamily: "Nexa-Regular",
        fontWeight: 100,
        fonSize: "14px",
        lineHeight: "26px",
        paragraph: "12px",
        marginTop: "10px"
    }
}

const buttonStyles = {
    borderRadius: "8px",
    fontFamily: "Nexa-Heavy",
    fontSize: "0.875rem",
    textTransform: "capitalize",
};
