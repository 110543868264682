import React  from 'react'
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { handleNavigation } from "../../../utilities/src/CustomHelpers";
import { imageArt, business } from "../assets"
import { prev, next, sponsored1, suggested1, suggested2 } from "../../../dashboard/src/assets";
import { getStorageData,setStorageData } from "../../../../framework/src/Utilities";
interface ArrowProps {
    onClick: () => void;
  }
export interface ApiCallInterface {
  contentType: string,
  method: string,
  endPoint: string,
  body?: {}
}

export interface CategoryStructure {
  id: string,
  type: string,
  attributes: {
    name: string,
    image: {
      url: string
    },
    created_at: string,
    updated_at: string
  }
}

export interface VaildResponse {
  data: {
    attributes: {
      podcast_categories_ids: [string]
    }
  }
}

interface TrandingDataList {
  data: Array<PodcastData>
}

interface TrandingEpisodesDataList {
  data: Array<PodcastEpisodesData>
}

export interface PodcastData {
  id:string,
  attributes: {
    follower: {
      following: boolean,
      follow_id: string
    },
    cover_image: string,
    name: string,
    description:string
  }
}

export interface PodcastEpisodesData {
  id:string,
  attributes: {
    liked_episode: {
      liked: boolean,
      liked_id: string
    },
    cover: string,
    name: string,
    description: string,
    save_episode: {
      saved: boolean,
      saved_id: string
    }
  }
}

export interface VaildResponseCategories {
  data: [CategoryStructure]
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path:string) => void;
  handlePodcastId:(id:string) => void;
  handleAddAudio: (value:string, id:string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sponsoredPodcasts: any,
  suggestedPodcasts: any,
  trendingPodcasts: any,
  favorateCategories: any,
  allCategories: any,
  categories: CategoryStructure[];
  favoriteCategoriesList: string[];
  categoryList: CategoryStructure[];
  trandingPodCastList: Array<PodcastData>;
  trandingEpisodesList: Array<PodcastEpisodesData>;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DiscoverController extends BlockComponent<Props, S, SS> {
// Customizable Area Start
  getProductApiCallId: any;
  getAccountApiCallId: string = "";
  getCategoryApiCallId: string = "";
  getPodcastApiCallId: string = "";
  getEpisodesApiCallId: string = "";
  followApiCallId: string = "";
  saveEpisodeApiCallId: string = "";
  likeEpisodeApiCallId: string = "";
  scrollRef: any = React.createRef();
// Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleGetTrendingPodcast();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    this.state = {
    // Customizable Area Start
    sponsoredPodcasts: [],
    suggestedPodcasts: [],
    trendingPodcasts: [],
    favorateCategories: [],
    allCategories: [],
    categories: [],
    favoriteCategoriesList: [],
    categoryList: [],
    trandingPodCastList: [],
    trandingEpisodesList: []
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.handleGetAccount()
    this.handleGetCategory()
    this.handleGetTrendingPodcast();
    this.handleGetTrendingEpisodes();
    this.setState({
      favorateCategories: [
        {id: 1, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 2, src: imageArt, alt: "image2", name: "Arts"},
            {id: 3, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 4, src: imageArt, alt: "image2", name: "Arts"},
            {id: 5, src: imageArt, alt: "image1", name: "Arts"}, 
      ],
        trendingPodcasts: [
            {id: 1, src: business, alt: "image1", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Follow"}, 
            {id: 2, src: business, alt: "image2", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Following"},
            {id: 3, src: business, alt: "image1", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Follow"}, 
            {id: 4, src: business, alt: "image2", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Following"},
            {id: 5, src: business, alt: "image1", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Follow"}, 
          ],
        sponsoredPodcasts: [
          {id: 1, src: sponsored1, alt: "image1", episode: "Episode 12", name: "The true"}, 
          {id: 2, src: sponsored1, alt: "image2", episode: "Episode 12", name: "The true"},
          {id: 3, src: sponsored1, alt: "image3", episode: "Episode 12", name: "The true"},
          {id: 4, src: sponsored1, alt: "image4", episode: "Episode 12", name: "The true"},
          {id: 5, src: sponsored1, alt: "image5", episode: "Episode 12", name: "The true"},
          {id: 6, src: sponsored1, alt: "image6", episode: "Episode 12", name: "The true"},
          {id: 7, src: sponsored1, alt: "image7", episode: "Episode 12", name: "The true"},
        ],
      suggestedPodcasts: [
        {id: 1, src: suggested1, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false}, 
        {id: 2, src: suggested2, alt: "image2", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false},
        {id: 3, src: suggested2, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true}, 
        {id: 4, src: suggested1, alt: "image2", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false},
        {id: 5, src: suggested1, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true}, 
      ],

      allCategories: [
          {id: 1, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 2, src: imageArt, alt: "image2", name: "Arts"},
            {id: 3, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 4, src: imageArt, alt: "image2", name: "Arts"},
            {id: 5, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 6, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 7, src: imageArt, alt: "image2", name: "Arts"},
            {id: 8, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 9, src: imageArt, alt: "image2", name: "Arts"},
            {id: 10, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 11, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 12, src: imageArt, alt: "image2", name: "Arts"},
            {id: 13, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 14, src: imageArt, alt: "image2", name: "Arts"},
            {id: 15, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 16, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 17, src: imageArt, alt: "image2", name: "Arts"},
            {id: 18, src: imageArt, alt: "image1", name: "Arts"}, 
            {id: 19, src: imageArt, alt: "image2", name: "Arts"},
            {id: 20, src: imageArt, alt: "image1", name: "Arts"}, 
      ]
       
      })
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
}

// Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }

      if (apiRequestCallId === this.followApiCallId) {
        this.handleFollowSuccessCallBack()
      }

      if (apiRequestCallId === this.saveEpisodeApiCallId) {
        this.handleSaveUnSaveSuccessCallBack()
      }

      if (apiRequestCallId === this.likeEpisodeApiCallId) {
        this.handleSaveUnSaveSuccessCallBack()
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start


  getSponsoredSlickSettings() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  
    return {
      dots: true,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: React.createElement(this.nextSponsoredArrow, { onClick: () => {}, innerWidth }),
      prevArrow: React.createElement(this.prevSponsoredArrow, { onClick: () => {}, innerWidth }),
    };
  }

 
  prevSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props
  
    return (
      <img
        src={prev}
        onClick={onClick}
        style={{
          position: 'absolute',
          left: "-2%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }}
        alt="Previous Arrow"
      />
    );
  };

  nextSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return (
      <img
        src={next} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          right: "-2%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Next Arrow"
      />
    );
  }

  getSuggestedSlickSettings(){
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

    return(
       {
        dots: true,
        infinite: false,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: React.createElement(this.nextSuggestedArrow, { onClick: () => {}, innerWidth }),
        prevArrow: React.createElement(this.prevSuggestedArrow, { onClick: () => {}, innerWidth }),
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1700, 
            settings: {
              slidesToShow: 5, 
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
        ]
       }
    )
  }
  nextSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img 
        data-test-id="nextSuggestedArrow"
        src={next} 
        onClick={onClick}
        style={{
          position: 'absolute',
          right: "-2%",
          top: "45%",
          transform: 'translateY(-45%)',
          cursor: 'pointer',
          zIndex: 1
        }} 
        alt="Next Arrow"
      />
    )
  }
  prevSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img
        data-test-id="preSuggestedArrow"
        src={prev} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          left: "-2%",
          top: "45%",
          transform: 'translateY(-45%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Previous Arrow"
      />
    )
  }

  apiCall = async (data: ApiCallInterface) => {
    const { method, endPoint, body, contentType } = data;

    let token = await getStorageData("token");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: JSON.parse(token),
        'Content-Type': contentType,
      })
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )

    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  isValidResponse = (responseJson: VaildResponse) => {
    return responseJson && (responseJson.data);
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: VaildResponse & VaildResponseCategories & TrandingDataList & TrandingEpisodesDataList) => {
    if (apiRequestCallId === this.getAccountApiCallId) {
      this.handleGetAccountResponse(responseJson);
    }

    if (apiRequestCallId === this.getCategoryApiCallId) {
      this.handleGetCategoryResponse(responseJson)
    }

    if (apiRequestCallId === this.getPodcastApiCallId) {
      this.podcastSuccessResponse(responseJson)
    }
  
    if (apiRequestCallId === this.getEpisodesApiCallId) {
      this.episodesSuccessResponse(responseJson)
    }  
  };

  handleGetAccount = async () => {
    this.getAccountApiCallId = await this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.accountsApiEndpoint
    })
  }

  handleGetAccountResponse = async (responseJson: VaildResponse) => {
    let data = responseJson.data;
    this.setState({ favoriteCategoriesList: data.attributes.podcast_categories_ids });
  }

  handleGetCategory = async () => {
    this.getCategoryApiCallId = await this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.getCategory
    })
  }

  handleGetCategoryResponse = async (responseJson: VaildResponseCategories) => {
    let data = responseJson.data;

    this.setState({ categories: data });
    let favoriteCategoriesList = this.state.favoriteCategoriesList;
    const categoryList = favoriteCategoriesList.reduce((categorieslist: any[], currentCategory: string) => {
      const filteredData = data.filter(
        (item: CategoryStructure) => item.attributes.name === currentCategory
      );
      return categorieslist.concat(filteredData);
    }, []);

    if (categoryList) {
      this.setState({ categoryList });
    }
  }

  handleGetTrendingPodcast = async () => {
    this.getPodcastApiCallId = await this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.getPodcastApiEndPoint
    })
  }

  podcastSuccessResponse = async (responseJson: TrandingDataList) => {
    this.setState({ trandingPodCastList:responseJson.data  });
  }

  handleGetTrendingEpisodes = async () => {
    this.getEpisodesApiCallId = await this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.getEpisodesApiEndPoint
    })
  }

   episodesSuccessResponse = async (responseJson: TrandingEpisodesDataList) => {
    this.setState({ trandingEpisodesList: responseJson.data });        
  }

  handleSubCategories = async (path: string, id: string, name: string) => {
    await setStorageData("subCatId", id)
    await setStorageData("catName", name)
    this.props.handleCommonPath(path)
  }

  handleFollow = async (podcastId: string, isFollow: boolean, follow_id: string) => {
    const body = {
      data: {
        followable_type: "BxBlockLogin::Podcast",
        followable_id: podcastId
      }
    };

    isFollow ?
      this.followApiCallId = await this.apiCall({
        contentType: configJSON.categoryApiContentType,
        method: configJSON.httpDeleteType,
        endPoint: `${configJSON.followPodcastEndPoint}/${follow_id}`
      }) :
      this.followApiCallId = await this.apiCall({
        contentType: configJSON.categoryApiContentType,
        endPoint: configJSON.followPodcastEndPoint,
        method: configJSON.httpPostType,
        body: body
      });
  }

  handleFollowSuccessCallBack = () => {
    this.handleGetTrendingPodcast();
  }

  handleSaveUnSave = async (saveableId: string, isSaved: boolean, saved_id: string) => {
    const body = {
      data: {
        saveable_type: "BxBlockLogin::Episode",
        saveable_id: saveableId
      }
    };

    isSaved ?
      this.saveEpisodeApiCallId = await this.apiCall({
        contentType: configJSON.categoryApiContentType,
        method: configJSON.httpDeleteType,
        endPoint: `${configJSON.saveEpisode}/${saved_id}`
      }) :
      this.saveEpisodeApiCallId = await this.apiCall({
        contentType: configJSON.categoryApiContentType,
        endPoint: configJSON.saveEpisode,
        method: configJSON.httpPostType,
        body: body
      });
  }

  handleSaveUnSaveSuccessCallBack = () => {
    this.handleGetTrendingEpisodes();
  };

  handlelikeUnLike = async (likeableId: string, isLiked: boolean, likeable_id: string) => {
    const body = {
      data: {
        likeable_type: "BxBlockLogin::Episode",
        likeable_id: likeableId
      }
    }

    isLiked ?
      this.likeEpisodeApiCallId = await this.apiCall({
        contentType: configJSON.categoryApiContentType,
        method: configJSON.httpDeleteType,
        endPoint: `${configJSON.likeEpisode}/${likeable_id}`
      }) :
      this.likeEpisodeApiCallId = await this.apiCall({
        contentType: configJSON.categoryApiContentType,
        endPoint: configJSON.likeEpisode,
        method: configJSON.httpPostType,
        body: body
      });
  }

  handlelikeUnLikeSuccessCallBack = () => {
    this.handleGetTrendingEpisodes();
  };
  // Customizable Area End
}
