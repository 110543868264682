// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
    Grid,
    TextField,    
} from "@mui/material";
import EmailRegistrationSocialLinksController, {
    Props,
} from "./EmailRegistrationSocialLinksController";
import { backgroundImg, image_socialLinks, logoSvg } from "./assets";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { Formik,Form } from 'formik'
import { setStorageData } from "../../../framework/src/Utilities";


export default class EmailRegistrationSocialLinks extends EmailRegistrationSocialLinksController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <StyleWrapperSocialLink>
                <Grid className="container">
                    <Grid sx={{ width: { xl: "45%", lg: "45%", md: "55%", sm: "100%", xs: "100%" }, padding: { xl: "3% 10%", lg: "3% 10%", md: "3% 10%", sm: "3% 10%", xs: "3% 5%" } }}>
                        <Formik
                            initialValues={{
                                facebook: "",
                                instagram: "",
                                x: "",
                                linkedin: ""
                            }}
                            data-test-id="formikWrapper"
                            onSubmit={(values) => {
                                setStorageData("SocialLinks",JSON.stringify(values))
                                handleNavigation("EmailRegistrationCategories",this.props)
                            }}
                        >
                            {({ handleChange, handleSubmit, values }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Box className="title">Social Media Links</Box>
                                    <Box className="logoImg">
                                        <img src={logoSvg} alt="logo" />
                                    </Box>

                                    <Grid className="fields">
                                        <Box>
                                            <label className="label">Facebook</label>
                                        </Box>
                                        <TextField
                                            id="outlined-basic"
                                            name="facebook"
                                            value={values.facebook}
                                            onChange={handleChange}
                                            sx={{
                                                marginTop: "4%",
                                                width: "100%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                    backgroundColor: "black", 
                                                    "&:-webkit-autofill": {
                                                      backgroundColor: "black !important", 
                                                      WebkitTextFillColor: "#CBD5E1 !important", 
                                                      WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                    },
                                                  },
                                            }}
                                        />
                                    </Grid>

                                    <Grid className="fields">
                                        <Box>
                                            <label className="label">Instagram</label>
                                        </Box>
                                        <TextField
                                            id="outlined-basic"
                                            name="instagram"
                                            value={values.instagram}
                                            onChange={handleChange}
                                            sx={{
                                                marginTop: "4%",
                                                width: "100%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                    "&:-webkit-autofill": {
                                                        backgroundColor: "black !important", 
                                                        WebkitTextFillColor: "#CBD5E1 !important", 
                                                        WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                    },
                                                    backgroundColor: "black", 
                                                  },
                                            }}
                                        />
                                    </Grid>

                                    <Grid className="fields">
                                        <Box>
                                            <label className="label">X</label>
                                        </Box>
                                        <TextField
                                            id="outlined-basic"
                                            name="x"
                                            value={values.x}
                                            onChange={handleChange}
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    backgroundColor: "black", 
                                                    color: "#CBD5E1",
                                                    "&:-webkit-autofill": {
                                                      backgroundColor: "black !important", 
                                                      WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                      WebkitTextFillColor: "#CBD5E1 !important", 
                                                    },
                                                  },
                                            }}
                                        />
                                    </Grid>

                                    <Grid className="fields">
                                        <Box>
                                            <label className="label">LinkedIn</label>
                                        </Box>
                                        <TextField
                                            id="outlined-basic"
                                            name="linkedin"
                                            value={values.linkedin}
                                            onChange={handleChange}
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },                                                
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                    backgroundColor: "black", 
                                                    "&:-webkit-autofill": {
                                                      backgroundColor: "black !important", 
                                                      WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                      WebkitTextFillColor: "#CBD5E1 !important", 
                                                    },
                                                  },
                                            }}
                                        />
                                    </Grid>

                                    <Button type="submit" className="btnSkip" data-test-id="submitBtn">
                                        <b style={{ textTransform: "initial" }}><span style={{color : "white",textDecoration:"none"}}>Skip this step</span></b>
                                    </Button>
                                    <Button type="submit" className="btnContinue" data-test-id="submitBtn">
                                        <b>Continue</b>
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                    <Grid className="rightContainer" sx={{ display: { xl: "flex", lg: "flex", md: "flex", sm: "none", xs: "none" }, width: { xl: "55%", lg: "55%", md: "45%", sm: "0", xs: "0" } }}>
                        <img src={image_socialLinks} alt="image_socialLinks" className="imageSignUp" />
                    </Grid>
                </Grid>
            </StyleWrapperSocialLink>

        )
    }
}

const StyleWrapperSocialLink = styled('div')({
    margin: "0 auto",
    height: "auto",
    width: "100%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .imageSignUp": {
        width: "100%",
        height: "auto",
    },
    "& .btnSkip": {
        width: "100%",
        height: "6%",
        color: "white",
        background: "#312F2D",
        fontFamily: "Nexa-Heavy",
        borderRadius: "8px",
        fontWeight: 900,
        fontSize: "18px",
        marginTop: "10%",
        textTransform: "capitalize",
        "&:hover": {
            background: "#312F2D",
            color: "white"
        },
    },
    "& .btnContinue": {
        width: "100%",
        height: "6%",
        color: "white",
        background: "#FF0807",
        fontFamily: "Nexa-Heavy",
        borderRadius: "8px",
        fontWeight: 900,
        fontSize: "18px",
        marginTop: "5%",
        textTransform: "capitalize",
        "&:hover": {
            background: "#FF0807",
            color: "white"
        },
    },
    "& .logoImg": {
        justifyContent: "center",
        display: "flex",
    },
    "& .label": {
        fontSize: "14px",
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900
    },
    "& .container": {
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row"
    },
    "& .title": {
        fontWeight: 900,
        fontSize: "20px",
        color: "white",
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        marginBottom: "4%",
        marginTop: "7%",
    },
    "& .fields": {
        marginTop: "4%"
    },
    "& .rightContainer": {
        justifyContent: "flex-start",
        paddingTop: "5%",
        alignItems: "flex-start",
    },
})
// Customizable Area End