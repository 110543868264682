Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.deleteApiMethod = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AudioLibrary";
exports.labelBodyText = "AudioLibrary Body";
exports.btnExampleTitle = "CLICK ME";
exports.addAudio = "bx_block_audiomusic2/audio_players";
exports.medilist= [
  {
      "id": "90",
      "type": "multiple_upload_file",
      "attributes": {
          "file_info": [
              {
                  "title": "Depression Audio clip",
                  "description": "Depression Audio clip",
                  "focus_areas": [
                      "68",
                      "73",
                      "56",
                      "90",
                      "93",
                      "95",
                      "97",
                      "99",
                      "100",
                      "102",
                      "103",
                      "104",
                      "112"
                  ],
                  "url": "https://niya-178517-ruby.b178517.stage.eastus.az.svc.builder.ai/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZW89IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--352f5ae7a7f2b89a15df04949e2984968324b704/Depression%20final.m4a",
                  "content_type": "audio/mp4"
              }
          ]
      }
  }]
exports.medArticleList=[
      {
          "id": "57",
          "type": "doc_item",
          "attributes": {
              "file_info": [
                  {
                      "title": "FINANCIAL_STRESS",
                      "description": "",
                      "focus_areas": [
                          "100",
                          "101",
                          "102",
                          "103",
                          "104"
                      ],
                      "url": "https://niya-178517-ruby.b178517.stage.eastus.az.svc.builder.ai/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBY1k9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--db3a974319edd7e65558e7af86c5aa9a0ed368b3/1676368218487_FINANCIAL_STRESS_-final.docx",
                      "content_type": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      "text_file_to_str": null,
                      "file_content":""
                  }
              ]
          }
      }
  ]
exports.addLike = "bx_block_like/likes";
exports.lastPlayed = "bx_block_audiomusic2/audio_players/last_played";
exports.lastPlayedAudio = "last_played"
exports.dashboarApiMethodDeleteType = "DELETE";
exports.postMethod = "POST";
exports.deleteMethod = "DELETE"
exports.saveEpisode = "bx_block_catalogue/saved_episodes";
exports.unSaveEpisode = "bx_block_catalogue/saved_episodes";
exports.addToPlayList = "bx_block_playlist/audio_play_lists/add_song";
exports.savedEpisode = "bx_block_catalogue/saved_episodes?type=episode";
exports.removeFromPlayList = "bx_block_playlist/audio_play_lists/remove_song"
exports.unFollowPodcast = "bx_block_catalogue/followers";
exports.followersPodcast = "bx_block_catalogue/followers?type=podcast";
// Customizable Area End