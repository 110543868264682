// Customizable Area Start
import React from "react";
import { styled, Box, Grid, Typography, Button, TextField, InputAdornment, IconButton, Dialog, DialogContent, Divider, DialogActions } from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined, StarRounded } from '@mui/icons-material';
import { goBack } from "./assets";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {ConditionalRender} from "../../utilities/src/ConditionalRender";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";

import ResetPasswordController, {
  Props
} from "./ResetPasswordController";

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  validationSchema = Yup.object().shape({
    resetPassword: Yup.string()
      .required('Password is required')
      .matches(/[A-Z]/, 'At least one capital letter')
      .matches(/[a-z]/, 'At least one lowercase letter')
      .matches(/\d/, 'At least one number')
      .min(8, 'Minimum character length is 8'),
    confirmPassword: Yup.string()
      .required('Please confirm your password')
      .oneOf([Yup.ref('resetPassword'), null], 'The Passwords Do Not match'),
  });
  render() {
    return (
      // Customizable Area Start
      <>
        <div style={AccountSettingwebStyles.container}>
        <StyledWrapperSetting>   
          <Box display="flex" alignItems="center">
            <img onClick={this.handleGoBack} src={goBack} alt="go Back" style={{ marginBottom: '20px', marginLeft: "8%", width: "2%" }} />
            <Typography variant="h4" sx={{ color: '#fff', marginBottom: '20px', marginLeft: "2%", fontWeight: "bold", fontFamily: "Nexa-Heavy", fontSize: "30px" }}>
              Settings - Reset Password
            </Typography>
          </Box>
          <Box className= "account-settings-container-1">
            <Box className= "outer-box-container">
                <Typography variant="h5" style={{ color: '#fff', fontSize: "20px", textAlign: 'center', padding: "2% 2% 1% 2%", fontFamily: "Nexa-Heavy" }}>
                    Change Your Password
                </Typography>
                <Typography variant="h5" sx={{ color: '#fff', textAlign: 'center', marginTop: "1%",marginLeft: "35%", fontFamily: "Nexa-Regular", width: "30%", hight: "56px", fontSize: "12px", fontWeight: "400px", lineHeight: "26px" }}>
                    Your new password must be different from the previous one.
                </Typography>
              <Formik
                initialValues={{ resetPassword: '', confirmPassword: '' }}
                enableReinitialize
                validationSchema={this.validationSchema}
                onSubmit={(values) => {
                  this.resetPasswordApi(values);
                }}
              >
                {({ errors, touched, handleChange, values, handleSubmit, initialValues }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Box sx={{ textAlign: "center", width: "70%" }}>
                          <Typography variant="body1" sx={{ color: "#CBD5E1", marginBottom: "8px", fontFamily: "Nexa-heavy", lineHeight: "26px", fontSize: "14px", marginLeft: "30.5%", marginTop: "4%", textAlign: "left" }}>
                            New Password<span>*</span>
                          </Typography>
                          <TextField
                            id="newPasswordField"
                            name="resetPassword"
                            type={this.state.showNewPassword ? "text" : "password"}
                            value={values.resetPassword}
                            onChange={handleChange}                             
                            autoComplete="off"
                            data-test-id="txtInputPassword"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={this.toggleNewPasswordVisibility} style={{ color: "#fff", cursor: "pointer" }} data-test-id="toggle-password">
                                    {this.state.showNewPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              padding: "10px 8px 10px 8px",
                              width: "40%",
                              borderRadius: "8px",
                              "& .MuiOutlinedInput-root": {
                                height: "56px",
                                "& fieldset": {
                                  borderColor: "#fff",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#fff",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#fff",
                                },
                                "& .MuiInputBase-input-MuiOutlinedInput-input": {
                                  height: "3.438em"
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#CBD5E1",
                              },
                              "& .MuiInputBase-input": {
                                backgroundColor: "transparent",
                                color: "#fff",
                                "&:-webkit-autofill": {
                                  backgroundColor: "transparent !important",
                                  WebkitTextFillColor: "#fff !important",
                                  WebkitBoxShadow: "0 0 0 1000px transparent inset",
                                },
                              },
                            }}
                          />
                        </Box>

                      </Grid>
                      <Box className="password-validation">
                        <ul style={{ listStylePosition: 'inside', padding: 0, color: "white", fontFamily: "Nexa-Heavy", textTransform: "capitalize" }}>
                          <li style={{
                            listStyleType: 'disc',
                            color: "white"
                          }}>Your Password Must Contain : </li>
                          <li
                            style={{
                              listStyleType: ResolveCondition((/[A-Z]/.test(values.resetPassword)), 'disc', '"X"'),
                              color: ResolveCondition((/[A-Z]/.test(values.resetPassword)), "white", "red"),
                            }}
                          >
                            <span style={{ color: ResolveCondition((/[A-Z]/.test(values.resetPassword)), "#34D399", "red") }}>{" "} At least one capital letter</span>
                          </li>
                          <li
                            style={{
                              listStyleType: ResolveCondition((/[a-z]/.test(values.resetPassword)), 'disc', '"X"'),
                              color: ResolveCondition((/[a-z]/.test(values.resetPassword)), "#fff", "#FF0807"),
                            }}
                          >
                            <span style={{ color: ResolveCondition((/[a-z]/.test(values.resetPassword)), "#34D399", "#FF0807") }}>{" "} At least one lowercase letter</span>
                          </li>
                          <li
                            style={{
                              listStyleType: ResolveCondition((/\d/.test(values.resetPassword)), 'disc', '"X"'),
                              color: ResolveCondition((/\d/.test(values.resetPassword)), "#fff", "#FF0807"),
                            }}
                          >
                            <span style={{ color: ResolveCondition((/\d/.test(values.resetPassword)), "#34D399", "#FF0807") }}>{" "} At least one number</span>
                          </li>
                          <li
                            style={{
                              listStyleType: ResolveCondition((values.resetPassword.length >= 8), 'disc', '"X"'),
                              color: ResolveCondition((values.resetPassword.length >= 8), "#fff", "#FF0807"),
                            }}
                          >
                            <span style={{ color: ResolveCondition((values.resetPassword.length >= 8), "#34D399", "#FF0807") }}>{" "}  Minimum character length is 8</span>
                          </li>
                        </ul>
                      </Box>
                      {ConditionalRender(errors, touched, 'resetPassword', <div className="errors" style={{ marginLeft: "-18%", marginTop: "0px" }}>{errors.resetPassword}</div>)}

                      <Grid container justifyContent="center" alignItems="center">
                        <Box sx={{ textAlign: "center", width: "70%" }}>
                          <Typography variant="body1" sx={{ color: "#CBD5E1", fontFamily: "Nexa-heavy", lineHeight: "26px", fontSize: "14px", marginLeft: "31%", marginTop: "1%", textAlign: "left", marginBottom: "8px" }}>
                            Confirm Password<span>*</span>
                          </Typography>
                          <TextField
                            id="confirmPassword"
                            name="confirmPassword"
                            type={this.state.showConfirmPassword ? "text" : "password"}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            data-test-id="txtInputConfirmPassword"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={this.toggleConfirmPasswordVisibility} style={{ color: "#fff", cursor: "pointer" }} data-test-id="toggle-confirm-password">
                                    {this.state.showConfirmPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              width: "40%",
                              borderRadius: "8px",
                              padding: "10px 8px 10px 8px",
                              marginTop: "1%",
                              "& .MuiInputLabel-root": {
                                color: "#CBD5E1",
                              },
                              "& .MuiOutlinedInput-root": {
                                height: "56px",
                                "& fieldset": {
                                  borderColor: "#fff",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#fff",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#fff",
                                },
                                "& .MuiInputBase-input-MuiOutlinedInput-input": {
                                  height: "3.438em"
                                },
                              },
                              "& .MuiInputBase-input": {
                                color: "#fff",
                                backgroundColor: "transparent",
                                "&:-webkit-autofill": {
                                  backgroundColor: "transparent !important",
                                  WebkitBoxShadow: "0 0 0 1000px transparent inset",
                                  WebkitTextFillColor: "#fff !important",
                                },
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      {values.confirmPassword !== values.resetPassword && (<div className="errors" style={{ marginLeft: "36.5%" }}>{errors.confirmPassword}</div>)}

                      <Button 
                      type="submit"
                       variant="contained" data-test-id="reset-password" sx={{
                        backgroundColor: '#FF0807',
                        color: '#fff',
                        width: "28%",
                        height: "56px",
                        fontFamily: "Nexa-Heavy",
                        textTransform: "none",
                        marginLeft: "36%",
                        marginTop: "2%",
                        marginBottom: "3%",
                        '&:hover': {
                          backgroundColor: '#FF0807',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#B2B3B3',
                          color: "#fff"
                        }
                      }}
                        disabled={(JSON.stringify(values) === JSON.stringify(initialValues)) || ((values.resetPassword !== values.confirmPassword))}
                      >
                        Reset my Password
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </StyledWrapperSetting>
        </div>
        <Footer navigation={this.props.navigation} id={"1"} />
        <Dialog open={this.state.openModal} PaperProps={{
          style: {
            margin: '0 auto',
            borderBottomRightRadius: '30px',
          },
        }}>
          <DialogContent sx={{ textAlign: "center", backgroundColor: "#44403C" }}>
            <IconButton size="large" className="modal-icon">
              <StarRounded fontSize="inherit" style={{ color: "#FF0807" }} />
            </IconButton>
            <Typography variant="h6" align="center" style={{ color: "#fff", fontFamily: 'Nexa-Heavy' }} gutterBottom>
              Success! 
            </Typography>
            <Typography variant="h6" align="center" style={{ color: "#fff", fontFamily: 'Nexa-Regular', width: "79%", marginLeft: "12%"}} gutterBottom>
              Your password has been successfully reset. 
            </Typography>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'center', backgroundColor: "#44403C", height: '28%' }}>
            <Button variant="contained" onClick={() => this.props.handleCommonPath("Dashboard")} style={{ backgroundColor: "#FF0807", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', padding: "16px 8px", borderRadius: "8px", margin: "5% 2%" }} data-test-id="resident-dashboard">
              Resident Dashboard
            </Button>
            <Button variant="contained" onClick={() => this.props.handleCommonPath("AccountSettings")} style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', padding: "16px", borderRadius: "8px", minWidth: "38%", margin: "5% 2%" }} data-test-id="settings">
              Settings
            </Button>
          </DialogActions>
        </Dialog>

      </>
      // Customizable Area End
    );
  }
}

const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  background: "black",
  paddingLeft: "calc(2% + 80px)",
  paddingRight: "6%",
  "& *": {
    boxSizing: "border-box",
  },
  "& .outer-box-container": {
    margin: "0 auto",
    position: "relative",
    borderRadius: "8px",
  },
  "& .account-settings-container-1": {
    backgroundColor: "#44403C",
    height: "auto",
    marginLeft: "8%",
    borderRadius: "8px",
    width: "88%"
  },
  "& .outer-heading": {
    color: "#fff",
    fontWeight: "900",
    fontSize: "30px",
    marginLeft: "5px",
  },
  "& .errors":{
    color : "#FF0807",
    fontFamily : "Nexa-Heavy",
    fontWeight : 400,
    fontSize :"12px",
    marginTop : "15px"
  },
  "& .password-validation": {
    marginLeft: "37%"
  }
  });

  const AccountSettingwebStyles = {
    container: {
      maxHeight: "100%",
      overflowY: "auto",
      scrollbarWidth: "thin",
      scrollbarColor: "red gray",
      "&::-webkit-scrollbar": {
        width: "12px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "red",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "gray",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-button": {
        display: "none",
      },
    } as const
  }


// Customizable Area End