import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { Box, Typography, Button, TextField, Grid, InputAdornment, styled } from "@mui/material";
import { micImg } from "./assets";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import AudioPlayerComponent from "../../audiolibrary/src/AudioPlayerComponent.web";
import React from "react";
import NewPlayListEditController, {Props} from "./NewPlayListEditController.web";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SearchIcon from "@mui/icons-material/Search";
import Slider from "react-slick";
import ImageCard from "./components/ImageCard.web";
import PlayListModal from "./components/PlayListModal.web";


const MIN_WINDOW_HEIGHT = 900


export default class NewPlayListEdit extends NewPlayListEditController {
    constructor(props: Props) {
      super(props);
    }
  
    render() {
        return (
            <>
                <div style={webStyles.container} ref={this.scrollBox}>
                <Box mb={3}>
                <Grid container spacing={2} mb={5}>
                    <Grid item md={3}>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={1}>
                                <img src={this.state.playlistInfo?.image} style={{width:"273px", height:"273px", borderRadius:"8px", objectFit:"cover"}} />
                                <Box>
                                    <Typography sx={{...webStyles.title, textAlign:"center"}}>{this.state.playlistInfo?.title}</Typography>
                                    <Box display={"flex"} justifyContent={"space-around"}>
                                    <Box
                                        padding={1}
                                        data-test-id="edit"
                                        bgcolor="#312F2D"
                                        borderRadius={1}
                                    >
                                        <EditIcon sx={{ color: "white", }} />
                                    </Box>
                                    <Box
                                        padding={1}
                                        bgcolor="#FF0807"
                                        data-test-id="play"
                                        borderRadius={1}
                                    >
                                        <PlayCircleOutlineIcon sx={{ color: "white", }} />
                                    </Box>
                                    <Box
                                        data-test-id="delete"
                                        padding={1}
                                        bgcolor="#312F2D"
                                        borderRadius={1}
                                    >
                                        <DeleteOutlineIcon sx={{ color: "white", }} />
                                    </Box>

                                    </Box>
                                </Box>
                        </Box>
                    </Grid>
                    <Grid item md={9}>
                    <Box>
                        <Box display={"flex"} justifyContent={"space-between"}> 
                            <Typography sx={webStyles.title}>{this.state.playlistInfo?.title}</Typography>
                            <Button
                                sx={{
                                    border: "none",
                                ...buttonStyles,
                                background: "#FF0807",
                                color: "white",
                                "&:hover": {
                                    background: "#FF0807", 
                                }
                                }}
                                startIcon={<PlaylistAddIcon />}
                                variant="contained"
                                onClick={this.handleAllPlaylist}
                            >
                                View All Playlists
                            </Button>
                        </Box>
                        {
                            this.state.selectedPlaylistInfo.length > 0 ?
                        <Box height={"400px"} p={1} color={"white"} bgcolor={"#44403C"} overflow="scroll" sx={{overflowX:"auto"}} data-test-id="songsContainer" >
                            {this.state.selectedPlaylistInfo.map((data, index) => (
                                <Box
                                    data-test-id="selected-playlist"
                                    key={index}
                                    display={"flex"}
                                    alignItems="center"
                                    justifyContent={"space-between"}
                                    borderBottom="1px solid #F5F5F5"
                                    padding={2}
                                    sx={{
                                        backgroundColor: index === this.state.selectedPlaylist ? "black" : "",
                                        cursor: "pointer",
                                      }}
                                    onClick={() => this.handlePlaylistRowClick(index)}
                                >
                                    <Box display="flex" flex={5} gap={1}>
                                    <img
                                        src={data.cover_image}
                                        alt={data.name}
                                        style={{ width: "40px", height: "40px", objectFit:"cover"}}
                                    />
                                    <Box>
                                        <Typography
                                        whiteSpace={"nowrap"}
                                        textOverflow={"ellipsis"}
                                        fontFamily={"Nexa-Heavy"}
                                        overflow={"hidden"}
                                        fontWeight={900}
                                        fontSize="16px"
                                        >
                                        {data.name}
                                        </Typography>
                                        <Typography
                                        fontWeight={400}
                                        fontFamily={"Nexa-Regular"}
                                        fontSize="14px"
                                        overflow={"hidden"}
                                        whiteSpace={"wrap"}
                                        textOverflow={"ellipsis"}
                                        >
                                        {data.description}
                                        </Typography>
                                    </Box>
                                    </Box>

                                    <Typography
                                    flex={1}
                                    fontSize="14px"
                                    fontWeight={400}
                                    fontFamily={"Nexa-Regular"}
                                    >
                                    {data.episode_time}
                                    </Typography>

                                    <Box
                                    bgcolor="#FF0807"
                                    borderRadius={1}
                                    data-test-id="deleteBtn"
                                    onClick={() => this.handleDeleteSong(data)}
                                    >
                                        {
                                           index === this.state.selectedPlaylist && this.state.isDeleteLoading ? 
                                            <Typography
                                            padding={1}
                                            color={"white"}
                                            fontWeight={"bold"}
                                            fontSize="14px"
                                            fontFamily={"Nexa-Regular"}
                                                >
                                                Deleting...
                                            </Typography>
                                            :
                                        <DeleteOutlineIcon sx={{ color: "white" }} />
                                        }
                                    </Box>
                                </Box>
                            ))}                                
                        </Box>
                        : 
                        <Box height={"400px"} color={"white"} bgcolor={"#44403C"} display={"flex"} alignItems={"center"} justifyContent={"center"} p={1}>
                            <Typography fontFamily={"Nexa-Regular"} data-test-id="nothingAdded" fontSize={"14px"}> 
                            Nothing Added Yet
                            </Typography>
                        </Box>
                        }
                    </Box>
                    </Grid>
                </Grid>

                {/* main playlist container  */}
                <Grid spacing={2} container >
                    <Grid md={3} item>
                        <Box>
                            <Typography sx={webStyles.title}>Search</Typography>
                            <Box mt={1}>       
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ fontSize: "20px", color: "white" }} />
                                        </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    placeholder="Search a podcast"
                                    fullWidth 
                                    sx={{
                                    "& .MuiOutlinedInput-input": {
                                        padding: "8px 2px", 
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        fontFamily: "Nexa-Heavy",
                                        borderRadius: "98px", 
                                        color:"white",
                                        border:"1px solid white",
                                    },
                                    }}
                                    value={this.state.searchInputValue}
                                    data-test-id="searchInput"
                                    onKeyDown={this.handlePodcastKeyDown}
                                    onChange={this.handleSearchPodcast}
                                />
                            </Box>
                            {
                                this.state.isPodcastListLoading ? 
                                <Box mt={2} width="97%" textAlign="center">
                                <Typography sx={webStyles.title}>Loading...</Typography>
                                </Box>
                                : 
                                <>
                                {
                                    this.state.podcastsListDatas.length !== 0 ? 
                                        <Box mt={2} overflow={"hidden"}>
                                            <StyledSearchSlider {...this.getSearchSlickSettings()}>
                                                {
                                                    this.state.podcastsListDatas.map((data, i) => {
                                                        return (
                                                            <ImageCard
                                                            songName={data.title}
                                                            artist={data.subtitle}
                                                            imageUrl={data.cover_image}
                                                            following={data?.follower?.following}
                                                            key={i}
                                                            onClick={() => this.handleCarouselCardClick(data)}
                                                        />
                                                        )
                                                    })
                                                }
                                            </StyledSearchSlider>
                                        </Box>
                                    : 
                                    <Box mt={2} width="97%" textAlign="center">
                                    <Typography sx={webStyles.title}>No podcasts available</Typography>
                                    </Box>
                                }
                                </>
                            }
                        </Box>
                    </Grid>
                    <Grid item md={9}>
                    <Box>
                        <Typography sx={webStyles.title}>From Main Playlist</Typography>
                        
                        <Box height={"400px"} p={1} color={"white"} bgcolor={"#44403C"} overflow="scroll" sx={{overflowX:"auto"}}>
                            {this.state.mainPlaylistInfo.map((data, index) => (
                                <Box
                                    key={index}
                                    data-test-id="playlist-item"
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    padding={2}
                                    borderBottom="1px solid #F5F5F5"
                                >
                                    <Box display="flex" flex={5} gap={1}>
                                    <img
                                        src={data.cover_image}
                                        style={{ width: "40px", height: "40px", objectFit:"cover" }}
                                        alt={data.name}
                                    />
                                    <Box>
                                        <Typography
                                        fontFamily={"Nexa-Heavy"}
                                        textOverflow={"ellipsis"}
                                        fontSize="16px"
                                        overflow={"hidden"}
                                        fontWeight={900}
                                        whiteSpace={"nowrap"}
                                        >
                                        {data.name}
                                        </Typography>
                                        <Typography
                                        textOverflow={"ellipsis"}
                                        whiteSpace={"wrap"}
                                        fontFamily={"Nexa-Regular"}
                                        fontWeight={400}
                                        fontSize="14px"
                                        overflow={"hidden"}
                                        >
                                        {data.description}
                                        </Typography>
                                    </Box>
                                    </Box>

                                    <Typography
                                    fontWeight={400}
                                    flex={1}
                                    fontSize="14px"
                                    fontFamily={"Nexa-Regular"}
                                    >
                                    {data.episode_time}
                                    </Typography>

                                    {
                                        this.checkIsEpisodeAdded(data.id) ? 
                                        <Button
                                        data-test-id="AddShowsToMyPlaylist"
                                        sx={{
                                            ...buttonStyles,
                                            color: "white",
                                            background: "#030303",
                                            border: "none",
                                            height:"none",
                                            width:"110px",
                                        fontSize:"10px",
                                        "&:hover": {
                                            background: "#030303", 
                                        }
                                        }}
                                        variant="contained"
                                        startIcon={<PlaylistAddCheckIcon />}
                                        >
                                        Added
                                        </Button> 
                                        :
                                        <Button
                                        data-test-id="AddShowsToMyPlaylist"
                                        sx={{
                                        ...buttonStyles,
                                        border: "none",
                                        background: "#FF0807",
                                        color: "white",
                                        width:"110px",
                                        fontSize:"10px",
                                        height:"none",
                                        "&:hover": {
                                            background: "#FF0807", 
                                        }
                                        }}
                                        startIcon={<PlaylistAddIcon />}
                                        onClick={() => this.handleAddBtnClick(data.id, index)}
                                        variant="contained"
                                    >
                                        { this.state.addSongId === index ? "Adding..." : "Add"}
                                    </Button>
                                    }
                                </Box>
                            ))}                                
                        </Box>
                    </Box>
                    </Grid>
                </Grid>


                </Box>
                <Footer navigation={this.props.navigation} id={"1"} />
                </div>

                {
                this.state.openPlaylistModal &&
                    <PlayListModal 
                        onClose={this.handleCloseModal} 
                        open={this.state.openPlaylistModal} 
                        episodeData={this.state.episodesList}
                        episodeDataLoading = {this.state.isEpisodeLoading} 
                        handleAddEpisode={(id) => this.handleEpisodesAdd(id)}
                        selectedData={this.state.selectedSearchPodcast}  
                        addEpisodeId = {this.state.addEpisodeId}
                        addEpisodeIsLoading = {this.state.isAddToPlaylistLoading}
                        handleDone = {this.handleDoneBtn}
                    />
            }      
 
            </>
        )

    }
}

const webStyles = {
    container: {
      padding:"20px 50px", overflowY: "auto" as const, maxHeight: "100%"},
    title : {
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: "40px",
        letter: "-0.5%",
        color: "#fff",
        fontFamily: "Nexa-Heavy"
    }
}

const buttonStyles = {
    borderRadius: "8px",
    fontFamily: "Nexa-Heavy",
    fontSize: "0.875rem",
    textTransform: "capitalize",
    height: "34px",
};

const StyledSearchSlider = styled(Slider)`
.slick-slide {
    width: none !important;
}
.slick-slider {
  display: block;
  position: relative;
  overflow: hidden; 
}
  .slick-list {
  display: flex;
  flex-wrap: nowrap; /* Prevents items from wrapping */
  overflow: hidden; /* Hides overflow */
}

.slick-track {
  display: flex;
  flex-wrap: nowrap; /* Prevents items from wrapping */
  transition: transform 0.5s ease-in-out; /* Smooth scrolling */
}
div.slick-slide.slick-active.slick-current {
  width: min-content !important;
}


`;