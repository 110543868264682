import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { ad_1 , Image_dots, podcastImage} from "./assets";

export interface ApiCallInterface {
  contentType: string,
  method: string,
  endPoint: string,
  body?: {}
}

export interface PostCommentObject  {
  attributes: {
    account_id: string,
    commentable_id: string,
    commentable_type: string,
    comment: string,
    created_at: string,
    account_details: {
      account_id: string,
      first_name: string,
      last_name: string,
      profile: string
    },
    likes:{
      liked: boolean,
      liked_id: string,
      count: string
    }
  },
  id: string,
  type: string
}

export interface PostCommentsSuccessResponse {
  data: Array<PostCommentObject>
}

export interface Post {
  data: [];
  id: string;
  type: string;
  attributes: PostAttributes;
}

interface PostAttributes {
  name: string | null;
  body: string;
  pinned: boolean;
  podcast_id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
  post_notification: boolean;
  share_post_count: number;
  post_url: string;
  photos: Photo[];
  account: Account;
  tags: string[];
  liked_post: LikedPost;
  save_post: SavePost;
  comments: Comments;
}

interface Photo {
  photot_link: string;
  photot_name: string;
  upload_date: string;
  photot_id: number;
}

interface Account {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface AccountAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  bio: string | null;
  profile_picture: string | null;
  user_location: string;
  roles: Role[];
  podcast_categories_ids: number[];
}

interface Role {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface LikedPost {
  liked: boolean;
  liked_id: string ;
  count: number;
}

interface SavePost {
  saved: boolean;
  saved_id: string ;
}

interface Comments {
  data: any[];
}

export interface HostsData{
  email: string;
  id : string;
  first_name : string ; 
  last_name : string;
  host_location : string;
  profile_picture : string;
  bio : string
}
export interface SubCategoryData{
  id : string;
  name : string ; 
}

export interface EpisodeData {
  id: string;
  type: string;
  attributes: {
    name: string;
    status: string;
    cover: string;
    description: string;
    episode_number: number;
    episode_content: string | null;
    adult: boolean;
    podcast_season: number;
    premium: boolean;
    episode_type: string;
    expiry_date: string;
    explicit_content: boolean;
    publish_date: string;
    episode_time: string;
    account: {
      id: number;
      first_name: string;
      last_name: string;
      full_phone_number: string;
      country_code: number;
      phone_number: number;
      email: string;
      activated: boolean;
      device_id: string | null;
      unique_auth_id: string;
      password_digest: string;
      created_at: string;
      updated_at: string;
      user_name: string;
      platform: string | null;
      user_type: string | null;
      app_language_id: string | null;
      last_visit_at: string | null;
      is_blacklisted: boolean;
      suspend_until: string | null;
      status: string;
      role_id: number | null;
      gender: string | null;
      date_of_birth: string;
      age: number | null;
      user_location: string;
      race: string | null;
      social_media_links: Record<string, string>;
      favorite_podcast_categories: any[];
      social_media_url: {
        x: string;
        facebook: string;
        instagram: string;
      };
      podcast_categories_ids: (number | null)[];
      bio: string | null;
      last_logged_in_at: string | null;
      stripe_id: string | null;
      main_playlist_id: string | null;
    };
    share_episode_count: number;
    episode_url: string;
    embed_code: string;
    chapters: {
      data: {
        id: string;
        type: string;
        attributes: {
          start_time: string;
          end_time: string;
          photo: string;
        };
      }[];
    };
    save_episode: {
      saved: boolean;
      saved_id: string ;
    };
    liked_episode: {
      liked: boolean;
      liked_id: string ;
      count: number;
    };
    comments: {
      data: any[];
    };
    added: {
      song_added: boolean;
    };
  };
}
interface PodcastData {
  podcast: {
    data: {
      id: string;
      type: string;
      attributes: {
        ratings: { ratings: number, reviews: number };
        name: string;
        description: string;
        location: string;
        explicit_lang: boolean;
        adult: boolean;
        timezone: string;
        subtitle: string | null;
        podcast_time: string;
        public_url : string;
        host_type: string;
        rss_url: string | null;
        share_channel_count: number;
        channel_url: string;
        embed_code: string;
        cover_image: string 
        categories: {
          data: Category[];
        };
        sub_categories: Array<SubCategoryData>;
        hosts: Array<HostsData>;
        episodes: {
          data: Array<EpisodeData>;
        };
        color_scheme: {
          data: ColorScheme;
        };
        follower: {
          following: boolean;
          follow_id: number;
        };
      };
    };
  };
  message: string;
}

interface Category {
  id: string;
  type: string;
  attributes: {
    name: string;
    image: {
      url: string;
    };
    created_at: string;
    updated_at: string;
  };
}

interface ColorScheme {
  id: string;
  type: string;
  attributes: {
    bg_color: string;
    secondry_color: string;
    text_color: string;
    button_color: string;
    template_type: string;
    logo: string | null;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleAddAudio: (type: string, id: string) => void;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  totalStars: number;
  reviewCount: number;
  ratingStars: number,
  fullStars: number;
  hasHalfStar: boolean;
  emptyStars: number;
  totalReviews: number;
  podcastName: string;
  followButtonText: string;
  selectedTab: string,
  selectedFile:any,
  textareaValue:string,
  communityData:any,
  isContactHost:boolean;
  name:string;
  title:string;
  description:string;
  review:string;
  phone_num:string;
  contactSuccess:boolean;
  errorMsg:string;
  open :boolean;
  openRateOrReview : boolean;
  showComments:boolean;
  comments: any;
  activeCommentId: any;
  openDropDown: null;
  openDropdownForMyOrOthersPost: string;
  openDropDownForOtherPost: null;
  currPostId: string;
  openPopup:boolean;
  anchorEl:any;
  communityListData : Array<Post>
  saveEpisodeListData: PodcastData;
  colorSchemeEpisodeData: any;
  userProfileData: any;
  hostData: any;
  openSharePostDialog: boolean;
  currentCommentId: string;
  postComments: Array<PostCommentObject>;
  nestedComments: Array<PostCommentObject>;
  nestedCommentId: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
    // Customizable Area Start
    communityPostCallId:string="";
    userDetailsApiCallId: string = "";
    contactHostCallId:string="";
    getCommunityDataApiCallId:string = "";
    getSaveEpisodesListApiCallId: string= "";
    saveEpisodeCatalogueCallId : string = "";
    addToPlaylistCallId : string = "";
    removePlaylistCallId : string = "";
    likeEpisodeCatalogueCallId : string = "";
    unlikeEpisodeCatalogueCallId : string = "";
    unsaveEpisodeCatalogueCallId: string = "";
    postReviewApiCallId: string = "";
    followPodcastApiCallId : string = "";
    unfollowPodacastApiCallId : string = "";
    likePostAPICallId: string = "";
    unlikePostAPICallId: string = "";
    getHostChannelDataApiCallId: string = "";
    savePostAPICallId: string = "";
    unSavePostAPICallId: string = "";
    turnOffNotificationOfMyPostAPICallId: string = "";
    deletePostAPICallId: string = "";
    hidePostAPICallId: string = "";
    commentOnPostApiCallId: string = "";
    getPostCommentsApiCallId: string = "";
    getNestedCommentsApiCallId: string = "";
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
    // Customizable Area Start
      totalStars: 5,
      reviewCount: 3.5,
      fullStars: 0,
      ratingStars: 0,
      hasHalfStar: false,
      emptyStars: 0,
      totalReviews: 0,
      podcastName: "Cost of Winning",
      followButtonText: "Follow",
      selectedTab: "Episodes",
      selectedFile:null,
      textareaValue:"",
      communityData:[]  ,
      isContactHost:false, 
      name:"",
      title:"",
      description:"",
      review : "",
      phone_num:"",
      contactSuccess:false,
      errorMsg:"",
      open : false,
      openRateOrReview: false,
      showComments:false,
      comments: [
        {
          id: 1,
          image: ad_1,
          title: "Jimmy Argylle",
          description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
          date: "08/15/24",
          likes: "900",
          comments: "120",
          dotsImage: Image_dots,
          subComments: [
            {
              id: 2,
              image: ad_1,
              title: "Michael C. Jordan",
              description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
              date: "08/15/24",
              likes: "900",
              comments: "120",
              dotsImage: Image_dots,
              subComments: [
                {
                  id: 3,
                  image: ad_1,
                  title: "Jack Mcguire",
                  description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
                  date: "08/15/24",
                  likes: "900",
                  comments: "120",
                  dotsImage: Image_dots,
                }
              ]
            }
          ]
        },
        {
          id: 4,
          image: ad_1,
          title: "Naty Velasco",
          description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
          date: "08/15/24",
          likes: "900",
          comments: "120",
          dotsImage: Image_dots,
          subComments: [
            {
              id: 5,
              image: ad_1,
              title: "Andrew Jackson",
              description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
              date: "08/15/24",
              likes: "900",
              comments: "120",
              dotsImage: Image_dots,
              subComments: [
                {
                  id: 6,
                  image: ad_1,
                  title: "Jeanine Mitchells",
                  description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
                  date: "08/15/24",
                  likes: "900",
                  comments: "120",
                  dotsImage: Image_dots,
                }
              ]
            }
          ]
        }
      ],
      activeCommentId: null,
      openDropDown : null,
      openDropdownForMyOrOthersPost: "",
      openDropDownForOtherPost: null,
      currPostId: "",
      openPopup:false,
      anchorEl:null,
      communityListData : [],
      saveEpisodeListData: {
        podcast: {
          data: {
            id: "",
            type: "",
            attributes: {
              name: "",
              description: "",
              ratings: { ratings: 0, reviews: 0 },
              location: "",
              explicit_lang: true,
              adult: true,
              timezone: "",
              subtitle: null,
              public_url : "",
              podcast_time: "",
              host_type: "",
              rss_url: null,
              share_channel_count: 0,
              channel_url: "",
              embed_code: "",
              cover_image: "",
              categories: {
                data: [
                  {
                    id: "",
                    type: "",
                    attributes: {
                      name: "",
                      image: {
                        url: ""
                      },
                      created_at: "",
                      updated_at: ""
                    }
                  }
                ]
              },
              sub_categories: [],
              hosts: [],
              episodes: {
                data: []
              },
              color_scheme: {
                data: {
                  id: "",
                  type: "",
                  attributes: {
                    bg_color: "",
                    secondry_color: "",
                    text_color: "",
                    button_color: "",
                    template_type: "",
                    logo: null
                  }
                }
              },
              follower: {
                following: false,
                follow_id: 0
              }
            }
          }
        },
        message: ""
      },
      colorSchemeEpisodeData: [],
      userProfileData: [],
      hostData: [],
      openSharePostDialog: false,
      currentCommentId: "",
      postComments: [],
      nestedCommentId: "",
      nestedComments: []
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getSaveEpisodeList()
    this.userDetails();
    this.getChannelData();
    // Customizable Area Start  
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.calculateStars(this.state.reviewCount);
     // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForCatalogueSaveEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.data.id) {
      this.getSaveEpisodeList()
    }
  }

  handleResForCatalogueUnSaveEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.message == "Successfully Unsaved") {
      this.getSaveEpisodeList()
    }
  }

  handleCatalogueLikeEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if(responseJson.data.id){
      this.getSaveEpisodeList()
    }
  }

  handleCatalogueunlikeEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
     if(responseJson.message === "Successfully destroy"){
      this.getSaveEpisodeList()
     }
  }

  handleAddToPlayListEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Song added successfully") {
      this.getSaveEpisodeList();                  
    }
  }

  handleRemovePlayListEpisode = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Song removed successfully") {
      this.getSaveEpisodeList();                  
    }
  }

  handleFollowPodacast = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if(responseJson.data.id){
      this.getSaveEpisodeList(); 
    }
  }
  
  handleUnfollowPodacast = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
      if(responseJson.message === "Successfully Unfollowed"){
        this.getSaveEpisodeList(); 
      }
  }
  
  handleResForRateAndReview = async(message : Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
     if(responseJson.message === "Review successfully submitted") {
      this.setState({ openRateOrReview : false });
      this.getSaveEpisodeList();
     }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const ApiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.communityPostCallId
    ) {
    
     this.setState({selectedFile:null,textareaValue:"",communityData:ApiResponse?.data})
     this.getCommunityPost();
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.contactHostCallId != null &&
      this.contactHostCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        this.setState({errorMsg:"Internal Server Error"})
      }
     
      if (responseJson.errors) {
        const errorMsg = responseJson.errors[0].phone_number || responseJson.errors[0].name || responseJson.errors[0]  ;
        this.setState({ errorMsg });
      }    
      if (responseJson && !responseJson.errors) {
        this.setState({ isContactHost: false, contactSuccess:true , errorMsg:"", title:"", description:"", name:"", phone_num:""});
      } 
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCommunityDataApiCallId != null &&
      this.getCommunityDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
         this.communitySuccessResponse(responseJson)
      }
    }

    if(this.saveEpisodeCatalogueCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForCatalogueSaveEpisode(message);
    }

    if(this.unsaveEpisodeCatalogueCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForCatalogueUnSaveEpisode(message);
    }

    this.validResponse(apiRequestCallId,ApiResponse)

    this.handleResForuserSettingsApi(from, message);
    this.handleLikePostRes(from, message);
    this.handleunLikePostRes(from, message);
    this.handleResForChannelData(from, message);
    this.handleResForSavePost(from, message);
    this.handleResForUnSavePost(from, message);
    this.handleResForTurnOffNotificationOfMyPost(from, message);
    this.handleResForDeletePost(from, message);
    this.handleResForHidePost(from, message);
  
    if(this.likeEpisodeCatalogueCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleCatalogueLikeEpisode(message)
    }

    if(this.unlikeEpisodeCatalogueCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleCatalogueunlikeEpisode(message)
    }
    if(this.postReviewApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForRateAndReview(message)
    }

    if(this.addToPlaylistCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleAddToPlayListEpisode(message)
    }
    if(this.removePlaylistCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleRemovePlayListEpisode(message)
    }
    if(this.followPodcastApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleFollowPodacast(message)
    }
    if(this.unfollowPodacastApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleUnfollowPodacast(message)
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSaveEpisodesListApiCallId != null &&
      this.getSaveEpisodesListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.getSaveEpisodeSuccessResponse(responseJson)
        this.getColorSchemeData(responseJson);
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  onCancelHandler = () => {
    this.setState({
      isContactHost: false,
    });
  }

  onCloseHandler = () => {
    this.setState({
      contactSuccess: false,
    });
  }

  onSendHandler = () => {
    this.setState({
      isContactHost: true,
    });
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  handleImageRender() {
    return this.state.saveEpisodeListData.podcast.data.attributes.cover_image || podcastImage;
  }

  saveContactHostData = async () => {
    let token = await getStorageData("token",true);
    let podcastId = await getStorageData("podcastId");
    const header = {
      token: token,
      "Content-Type": configJSON.productApiContentType,
    };

    const data = {
      message: {
        podcast_id: podcastId,
        name: this.state.name,
        title: this.state.title,
        description: this.state.description,
        phone_number: this.state.phone_num,
      },
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.contactHostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postContactHost
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 
  calculateStars = (reviewCount: number) => {
    const fullStars = Math.floor(reviewCount);
    const hasHalfStar = reviewCount % 1 !==0 ? true : false;
    const emptyStars  = this.state.totalStars - fullStars - (hasHalfStar ? 1 : 0)
    this.setState({fullStars, hasHalfStar, emptyStars});
  }

  handleFollowButton = async(podcastId : string) => {
      let token = await getStorageData("token");
      const header = {
        "Content-Type": "application/json",
        token: JSON.parse(token),
      };
      const body = {
        data: {
          followable_type: "BxBlockLogin::Podcast", 
          followable_id: podcastId
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followPodcastApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.followPodcastEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.followApiMethodPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnFollowButton = async(itemId : number ) => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unfollowPodacastApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unfollowPodcastEndPoint}/${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.unfollowApiMethodPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  handleLikePost = async (itemId: string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        likeable_type: "BxBlockCommunityforum::Post", 
        likeable_id: Number(itemId)
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.likePostAPICallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.likePostAPIEndPoint}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleUnlikePost(itemId: number) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unlikePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unLikePostAPIEndPoint}/${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleTabChanges = (tab: string) => {
    const newTab = tab;
    this.setState({selectedTab: newTab, showComments:false}, () => {
      if(this.state.selectedTab === "Community"){
       this.getCommunityPost()
      }else if(this.state.selectedTab === "Episodes" || this.state.selectedTab === "ChannelInfo" ){
        this.getSaveEpisodeList()
      }
    });
  }
   handleFileUpload = (event:any) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        selectedFile:file
      })
    }
  };

  handleRemoveFile = () => {
  this.setState({
    selectedFile:null
  })  };

  handleTextareaChange = (event:any) => {
    this.setState({ textareaValue: event.target.value });
  };

  handleCopyChannelUrl() {
    let copyChannelText = document.getElementById("podcastUrl");
    if(copyChannelText) {
      navigator.clipboard.writeText(copyChannelText.innerText);
    }
  }

  handleCopyEmbedUrl() {
    let copyEmbedText = document.getElementById("embedUrl");
    if(copyEmbedText) {
      navigator.clipboard.writeText(copyEmbedText.innerText);
    }
  }

  handleCopyPostUrl() {
    let copyChannelText = document.getElementById("postUrl");
    if(copyChannelText) {
      navigator.clipboard.writeText(copyChannelText.innerText);
    }
  }

  handleProvideStars(key: number) {
    if(this.state.ratingStars === key+1) {
      this.setState({ ratingStars: key });
    } else {
      this.setState({ ratingStars: key+1 });
    }
  }

  getSaveEpisodeList = async () => {
    let token = await getStorageData("token",true);
    let podcastId = await getStorageData("podcastId")
    let myPodcastId = await getStorageData("podcast_id")
    let myChannel = await getStorageData("isShowInMeddile");
    let id = myChannel == "MyChannel" ? myPodcastId : podcastId
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSaveEpisodesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSavedEpisodesEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnLikeEpisode = async(itemId : string) => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unlikeEpisodeCatalogueCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postUnlikeEpisodeEndPoint}/${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeApiMethodDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLikeEpisode = async(itemId : string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        likeable_type: "BxBlockLogin::Episode", 
        likeable_id: itemId
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.likeEpisodeCatalogueCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.postLikeEpisodeEndPoint}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.likeApiMethodPostType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAddtoPlaylist = async(song_id : string) => {
    let token = await getStorageData("token")
    let playListId = await getStorageData("main_playlist_id");
    const header = {
      token: JSON.parse(token),
    };
   
    let formdata = new FormData();
    formdata.append("id", playListId);
    formdata.append("song_id", `${song_id}`);

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.addToPlaylistCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.addToPlayEpisodeEndPoint}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.addToPlayistMethodPostType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRemovetoPlaylist = async(song_id : string) => {
    let token = await getStorageData("token")
    let playListId = await getStorageData("main_playlist_id");
    const header = {
      token: JSON.parse(token),
    };
   
    let formdata = new FormData();
    formdata.append("id", playListId);
    formdata.append("song_id", `${song_id}`);

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.removePlaylistCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.removePlaylistEndPoint}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.removePlaylistMethodDeleteType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSaveForLater = async(id : string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        saveable_type: "BxBlockLogin::Episode", 
        saveable_id: id
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.saveEpisodeCatalogueCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.catalogueSaveEpisode}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.catalogueApiMethodPostType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnSaveForLater = async(id : string) => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unsaveEpisodeCatalogueCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.catalogueUnSaveEpisode}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.catalogueApiMethodDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCommunityPost = async () => {
    let token = await getStorageData("token",true);
    let prodcastId = await getStorageData("podcastId");
    let myPodcastId = await getStorageData("podcast_id")
    let myChannel = await getStorageData("isShowInMeddile");
    let communityId = myChannel == "MyChannel" ? myPodcastId : prodcastId
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommunityDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCommunityEndPoint + communityId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkIfDisabled1() {
    if(this.state.textareaValue || this.state.selectedFile) {
      return this.getButtonColor();
    } else {
      return "#DADADA"
    }
  }

  checkIfDisabled2() {
    return !this.state.name || !this.state.title || !this.state.description;
  }

  renderPostImage(item: any) {
    return item.attributes.photos === null ? "" : item.attributes.photos[0]?.photot_link;
  }

  renderBlockOrFlex(item: any) {
    if(item.attributes.photos === null) {
      return "none";
    } else {
      return "flex"
    }
  }

  postRateOrReviewData = async () => {
    let token = await getStorageData("token",true);
    let podcastId = await getStorageData("podcastId");

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    let body = {
      podcast_review: {
        rating: this.state.ratingStars === 0 ? 1 : this.state.ratingStars,
        description: this.state.review
      }
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.postReviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postReviewAPIEndpoint}${podcastId}/podcast_reviews`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForuserSettingsApi = async (from: string, message: Message) => {
    if (this.userDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({ userProfileData: responseJson.data });
      }
    }
  };

  handleResForChannelData(from: string, message: Message) {
    if (this.getHostChannelDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Channel found!") {
        this.setState({ hostData: responseJson.podcast.data })
      }
    }
  }

  handleResForSavePost(from: string, message: Message) {
    if (this.savePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.meta.message === "Successfully Saved") {
        this.handleMenuCloseForHostUser();
        this.handleMenuCloseForOtherUser();
        this.getCommunityPost();
      }
    }
  }

  handleResForUnSavePost(from: string, message: Message) {
    if (this.unSavePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Successfully Unsaved") {
        this.handleMenuCloseForHostUser();
        this.handleMenuCloseForOtherUser();
        this.getCommunityPost();
      }
    }
  }

  handleResForTurnOffNotificationOfMyPost(from: string, message: Message) {
    if (this.turnOffNotificationOfMyPostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Post notification is turned on" || responseJson.message === "Post notification is turned off") {
        this.handleMenuCloseForHostUser();
        this.handleMenuCloseForOtherUser();
        this.getCommunityPost();
      }
    }
  }

  handleResForDeletePost(from: string, message: Message) {
    if (this.deletePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Post deleted successfully") {
        this.handleMenuCloseForHostUser();
        this.getCommunityPost();
      }
    }
  }

  handleResForHidePost(from: string, message: Message) {
    if (this.hidePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Post is hidden") {
        this.handleMenuCloseForOtherUser();
        this.getCommunityPost();
      }
    }
  }

  handleLikePostRes(from: string, message: Message) {
    if (this.likePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.getCommunityPost();
      }
    }
  }

  handleunLikePostRes(from: string, message: Message) {
    if (this.unlikePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Successfully destroy") {
        this.getCommunityPost();
      }
    }
  }

  communitySuccessResponse =(communityDataList: Post)=>{
    this.setState({communityListData : communityDataList.data })
  }

  getSaveEpisodeSuccessResponse = (episodedata : PodcastData)=>{
    console.log(episodedata);
   this.setState({saveEpisodeListData : episodedata})
  }

  getColorSchemeData(response: any) {
    this.setState({colorSchemeEpisodeData: response.podcast.data.attributes.color_scheme.data})
  }

  getBackgroundColor() {
    return this.state.colorSchemeEpisodeData?.attributes?.bg_color;
  }

  getSecondaryColor() {
    return this.state.colorSchemeEpisodeData?.attributes?.secondry_color;
  }

  getTextColor() {
    return this.state.colorSchemeEpisodeData?.attributes?.text_color;
  }

  getButtonColor() {
    return this.state.colorSchemeEpisodeData?.attributes?.button_color;
  }

  userDetails = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async getChannelData() {
    const token = await getStorageData('token');

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHostChannelDataApiCallId = requestMessage?.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEpisodeDataEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: JSON.parse(token)
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePostName() {
    let name = this.state.userProfileData.attributes.first_name +" "+ this.state.userProfileData.attributes.last_name; 
    let loggedInUserEmail = this.state.userProfileData.attributes.email;
    let currPodCastUsersEmail = this.state.saveEpisodeListData.podcast.data.attributes.hosts[0].email

    if(loggedInUserEmail === currPodCastUsersEmail) {
      name = this.state.saveEpisodeListData.podcast.data.attributes.name;
    }
    return name;
  }

  handleSend = async () => {
    let token = await getStorageData("token",true);
    let prodcastId = await getStorageData("podcastId");

    let name = this.handlePostName();

    const header = {
      token: token,
    };

    let formdata = new FormData();
    formdata.append("data[podcast_id]",prodcastId)
    formdata.append("data[body]", this.state.textareaValue);
    formdata.append("data[name]", name);
    if(this.state.selectedFile) {
      formdata.append("data[photos][]", this.state.selectedFile);
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.communityPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCommunityEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async handleSavePostDropDown(itemId: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        saveable_type: "BxBlockCommunityforum::Post", 
        saveable_id: Number(itemId)
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.savePostAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleUnSavePostDropDown(itemId: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unSavePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unSavePostAPIEndPoint}/${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleTurnOffNotificationOfMyPost(itemId: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.turnOffNotificationOfMyPostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.turnOffNotificationOfMyPostAPIEndPoint}/${itemId}/toggle_notification`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleDeletePost(id: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deletePostAPIEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleHidePost(id: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hidePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hidePostAPIEndPoint}/${id}/hide_post`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  renderPinnedBorder(post: any) {
    return post.pinned ? "5px solid #EC2025" : "none";
  }

  checkBorderRadius1() {
    return (this.state.selectedTab === 'Episodes' ? '9px 9px 0px 0px' : '9px')
  }

  checkBorderRadius2() {
    return (this.state.selectedTab === 'Community' ? '9px 9px 0px 0px' : '9px')
  }

  checkBorderRadius3() {
    return (this.state.selectedTab === 'ChannelInfo' ? '9px 9px 0px 0px' : '9px')
  }

  checkHeight1() {
    return (this.state.selectedTab === 'Episodes' ? '53px' : '40px')
  }

  checkHeight2() {
    let calculatedHeight;
    if (this.state.selectedTab === 'Community') {
      calculatedHeight = this.state.showComments ? "73px" : "53px";
    } else {
      calculatedHeight = "40px";
    }
    return calculatedHeight;
  }

  checkHeight3() {
    return (this.state.selectedTab === 'ChannelInfo' ? '53px' : '40px')
  }

  handleOpenShareChannel = () => {
   this.setState({open : true})
  }

  handleOpenSharePostModal(id: string) {
    this.setState({ openSharePostDialog: true, currPostId: id });
  }

  handleCloseSharePostModal = () => {
    this.setState({ openSharePostDialog: false });
  }

  handleCloseShareChannel = () => {
    this.setState({open : false})
  }
  
  handleOpenRateOrReview = () => {
    this.setState({openRateOrReview : true, ratingStars: 0, review: ""})
   }
 
   handleCloseRateOrReview = () => {
     this.setState({openRateOrReview : false})
   }

  
  showCommentBox = (event:React.MouseEvent<HTMLDivElement> | number) => {
    if(typeof event !== "number"){
      const commentId = Number(event.currentTarget?.getAttribute('data-id'));
      this.setState({ activeCommentId: commentId });
    }   
  };
  
  handleClick = (event:any, id:string) => {
    this.setState({ anchorEl: event.currentTarget, openPopup: !this.state.openPopup, activeCommentId: id });
  };
  
  handleClose = () => {
    this.setState({ anchorEl: null, openPopup: !this.state.openPopup });
  };

  handleOpenDropDownForHostUser = (event: any, id: string, key: number) => {
    this.setState({openDropDown: event?.currentTarget, currPostId: id, openDropDownForOtherPost: event?.currentTarget});
    if(this.state.userProfileData.attributes?.email === this.state.communityListData[key]?.attributes.account.attributes.email) {
      this.setState({ openDropdownForMyOrOthersPost: "My Post" });
    } else {
      this.setState({ openDropdownForMyOrOthersPost: "Others Post" });
    }
  }

  openMenu(item: any) {
    return Boolean(this.state.openDropDown) && item.id === this.state.currPostId
  }

  openMenuForOthersPost(item: any) {
    return Boolean(this.state.openDropDownForOtherPost) && item.id === this.state.currPostId
  }

  openSharePost(item: any) {
    return this.state.openSharePostDialog && item.id === this.state.currPostId
  }

  handleMenuCloseForHostUser = () => {
    this.setState({ openDropDown: null});
  };

  handleMenuCloseForOtherUser = () => {
    this.setState({ openDropDownForOtherPost: null});
  };

  apiCall = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = data;

    let token = await getStorageData("token");

    let header = {
      token: JSON.parse(token),
      'Content-Type': contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  validResponse = async (apiRequestCallId: string, responseJson: PostCommentsSuccessResponse) => {
    if (this.commentOnPostApiCallId === apiRequestCallId) {
      this.handleReplySuccessCallBack();
    }

    if (this.getPostCommentsApiCallId == apiRequestCallId) {
      this.handleGetPostCommentSuccessCallBack(responseJson);
    }

    if(this.getNestedCommentsApiCallId == apiRequestCallId){
      this.handleGetNestedCommentSuccessCallBack(responseJson)
    }
  };

  showComments = (id: string) => {
    if (this.state.currentCommentId == "" || this.state.currentCommentId !== id) {
      this.setState({ currentCommentId: id, postComments: [] });
      this.handleGetPostComment(id);
    } if (this.state.currentCommentId == id) {
      this.setState({ currentCommentId: "", postComments: [] });
    }     
  };

  handleReply = async (parentId: string, commentType: string) => {
    let comment = {
      comment: {
        commentable_id: parentId,
        commentable_type: commentType == "post" ? "BxBlockCommunityforum::Post" : "BxBlockComments::Comment",
        comment: this.state.textareaValue
      }
    };
    this.commentOnPostApiCallId = await this.apiCall({ 
      contentType: configJSON.productApiContentType, 
      method: configJSON.likeApiMethodPostType, 
      endPoint: configJSON.postComments, 
      body: comment
     });
  };

  handleReplySuccessCallBack = async () => {
    this.setState({ textareaValue: "" },()=>{
      this.handleGetPostComment(this.state.currentCommentId);
      this.handleGetNestedComment(this.state.nestedCommentId);
    });
  };

  handleGetPostComment = async (id: string) => {
    this.getPostCommentsApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getPostComments}${id}`,
    });
  };

  handleGetPostCommentSuccessCallBack = async (responseJson: PostCommentsSuccessResponse) => {
    if (responseJson.data) {
      this.setState({ postComments: responseJson.data });
    };
  };

  showNestedComments = (id: string) => {
    if (this.state.nestedCommentId == "" || this.state.nestedCommentId !== id) {
      this.setState({ nestedCommentId: id, nestedComments: [] });
      this.handleGetNestedComment(id);
    } if (this.state.nestedCommentId == id) {
      this.setState({ nestedCommentId: "", nestedComments: [] });
    };
  };

  handleGetNestedComment = async (id: string) => {
    this.getNestedCommentsApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getNestedComments}${id}`,
    });
  };

  handleGetNestedCommentSuccessCallBack = async (responseJson: PostCommentsSuccessResponse) => {
    if (responseJson.data) {
      this.setState({ nestedComments: responseJson.data });
    };
  };
  // Customizable Area End
}
