import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { URLSearchParams } from "url";
import React from "react"
import { leftArrow, rightArrow } from "../../dashboard/src/assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
interface ArrowProps {
  onClick: () => void;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path:string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  searchText: string;
  searchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;

  searchValue : string;
  searchPodcast : any;
  searchEpisode : any;
  searchPost : any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  searchApiCallId: any;
  searchResultsApiCallId : any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SearchTextMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      searchText: "",
      searchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,

      searchValue: "",
      searchPodcast: [],
      searchEpisode: [      
      ],
      searchPost: [
      ],
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    // Customizable Area Start 
    let searchQuery = await getStorageData("previousAllSearchResult");
    if(searchQuery){
      this.getSearchResultsApi()
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start  
    if(this.searchResultsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForSearchApi(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start

sendSearchText = (value:string) => {
  let msgData = new Message(getName(MessageEnum.SearchTextMessage))
  msgData.addData(getName(MessageEnum.SearchMessageText), {
    searchText: value
  })
  this.send(msgData)
}

  handleSeeAllResults =async () =>{
    let lastPath = await getStorageData("isShowInMeddile")
    setStorageData("previousAllSearchResult",JSON.stringify({
      "previousAllSearchResult" : lastPath,
      "searchQuery" : this.state.searchValue
    }))
    this.sendSearchText(this.state.searchValue)
    this.setState({ searchValue: "" })    
    this.props.handleCommonPath("AllSearchList")
  }

  handleResForSearchApi = async (message: Message) => {
    if (this.searchResultsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      if (responseJson) {
        this.setState({searchPodcast : responseJson?.podcasts.data ,searchEpisode : responseJson?.episodes.data,searchPost : responseJson?.posts.data})     
      }    
    }
  };
  
  
  getSearchResultsApi =async() =>{
    let token = await getStorageData("token");   
    let apiUrl = `${configJSON.searchResults}?query=${this.state.searchValue || ""}&per_page=6&page=1`;  
    const header = {
      token: JSON.parse(token),
    };

    const searchrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchResultsApiCallId = searchrequest.messageId;
    searchrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    searchrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    searchrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(searchrequest.id, searchrequest);
  }
  
  handleSearchChange = (event: any) => {
    this.setState({
      searchValue: event.target.value
    },this.getSearchResultsApi)
  }

  txtInputSearchTextProps = {
    onChangeText: (text: string) => {
      this.setState({ searchText: text });
    },
  };

  setSearchText = (text: string) => {
    this.setState({ searchText: text });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  getSearchList = (token: string) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const attrs = {
      query: this.state.searchText,
    };

    this.searchApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
