import React, { Component } from "react";
import { Box, Button, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

interface PlaylistItemProps {
  imageSrc: string | undefined;
  title: string;
  subtitle: string;
  date: string | null;
  duration: string | null;
  song_added: boolean;
  episodeId: string;
  handleAddAudio: (playable_type: string, playable_id: string) => void;
  handleAddBtn: (id: string) => void;
}

export class PlaylistItem extends Component<PlaylistItemProps> {
  render() {
    const { imageSrc, title, subtitle, date, duration, song_added, handleAddAudio, episodeId, handleAddBtn} = this.props;

    return (
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
        borderBottom="1px solid #F5F5F5"
        padding={2}
      >
        <Box display="flex" flex={2} gap={1}>
          <img src={imageSrc} alt={title} style={{ width: "40px", height: "40px", objectFit:"cover" }} />
          <Box>
            <Typography fontFamily={"Nexa-Heavy"}  textOverflow={"ellipsis"} whiteSpace={"nowrap"} overflow={"hidden"} fontWeight={900} fontSize="16px">{title}</Typography>
            <Typography textOverflow={"ellipsis"} whiteSpace={"wrap"} overflow={"hidden"} fontFamily={"Nexa-Regular"} fontWeight={400} fontSize="14px">{subtitle}</Typography>
          </Box>
        </Box>
        <Typography flex={1} textOverflow={"ellipsis"} whiteSpace={"nowrap"} overflow={"hidden"} fontFamily={"Nexa-Regular"} fontWeight={400} fontSize="14px" >{date}</Typography>
        <Typography flex={1} fontFamily={"Nexa-Regular"} fontWeight={400} fontSize="14px">{duration}</Typography>
        <Box display={"flex"} flex={1} gap={2}>
          <Box
          data-test-id="playIcon"
          onClick={() => handleAddAudio("BxBlockLogin::Episode", episodeId)} 
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "red",
              color: "white",
              borderRadius: "12px",
              padding: "2px",
            }}
          >
            <PlayCircleOutlineIcon sx={{ fontSize: 30 }} />
          </Box>
          {
            song_added &&
              <Button
              data-test-id="addButton"
                sx={{
                  border: "none",
                  color: "white",
                  background: "#030303",
                  borderRadius:"8px",
                  width:"110px",
                  fontWeight: 900,
                  fontSize: "14px",
                  fontFamily: "Nexa-Heavy",
                  textTransform:"none",
                  "&:hover": {
                    border: "none",
                    background: "#030303",
                  },
                }}
                variant="contained"
                startIcon={<PlaylistAddCheckIcon />}
                onClick={ () => handleAddBtn(episodeId) }
              >
                Added
              </Button>
            // :
            //   <Button           
            //     sx={{
            //       border: "none",
            //       color: "white",
            //       background: "#FF0807",
            //       borderRadius:"8px",
            //       fontWeight: 900,
            //       fontSize: "14px",
            //       fontFamily: "Nexa-Heavy",
            //       textTransform:"none",
            //       width:"110px",
            //       "&:hover": {
            //         border: "none",
            //         background: "#FF0807",
            //       },
            //     }}
            //     variant="contained"
            //     startIcon={<PlaylistAddIcon />}
            // >
            //     Add
            // </Button>
          }
        </Box>
      </Box>
    );
  }
}
