import React from "react";
import {
  Box,
  styled
} from "@mui/material";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web"
import Footer from "../../../blocks/navigationmenu/src/Footer.web"; 
import AudioCard from "./components/AudioCard.web";
import AudioPlayerComponent from "./AudioPlayerComponent.web";
import ChannelLibraryController, {
  Props
} from "./ChannelLibraryController";
const MIN_WINDOW_HEIGHT = 900


export default class SavedEpisode extends ChannelLibraryController {
  constructor(props: Props) {
    super(props);
    
  }

  render() {
    const { savedEpisode } = this.state;
    return (
      <>
          <div style={webStyles.container}>
          <Box mb={3}> 
            <CustomTypography data-test-id ={"saved_episode"} sx={{margin:"16px 0px"}}>
            Saved Episodes
          </CustomTypography>
          <Box display={"flex"} flexWrap={"wrap"} gap={5}>
            {
              savedEpisode.map((data, index) => (
                <AudioCard 
                imageUrl={data.imageUrl}
                key={index} 
                artist={data.artist}
                songName={data.songName}
                added={data.added}
                selected={data.selected}
                onRemoveSaveLater={() => {}}
                onAddToPlayList={() => {}}
                episodeId={data.episodeId}
                addPlayAudio={this.props.handleAddAudio}
                removeFromPlayList={() => {}}
                />
              ))
            }
          </Box>
          </Box>
          <Footer navigation={this.props.navigation} id={"1"} />
          </div>
        </>
    );
  }
}


const CustomTypography = styled(Box)(() => ({
  fontWeight: 900,
  fontSize: "30px",
  lineHeight: "40px",
  letter: "-0.5%",
  color: "#fff",
  fontFamily: "Nexa-Heavy"
}));

const webStyles = {
  container: {
    padding:"20px 50px",
  } as const,
}