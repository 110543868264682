import React, { Component } from 'react';
import {
    Typography,
    Box,
    Card
  } from "@mui/material";
  import { Favorite, PlaylistAdd } from '@mui/icons-material';
  import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
  import { heart, likedBtn, playBtn } from '../assets'


interface AudioCardProps {
  imageUrl: string | null;
  selected: boolean;
  added: boolean;
  songName: string;
  artist: string;
  onRemoveSaveLater: () => void;
  onAddToPlayList: () => void;
  episodeId: string;
  addPlayAudio: (id: string, type: string) => void;
  removeFromPlayList: () => void;
  onAddToSave?: () => void;
}

class AudioCard extends Component<AudioCardProps> {
  constructor(props: AudioCardProps) {
    super(props);
  }

  render() {
    return (
      <Card sx={{...audioCardStyles.cardContainer}}>
        <Box sx={audioCardStyles.imageContainer}>
            <Box sx={{...audioCardStyles.followingText}}
            >
              <Box display={"flex"} gap={2} flexDirection={"column"} sx={{ justifySelf: "flex-end", fontWeight:"bold" }}>
                  <img data-test-id = "saveLaterTestBtn" onClick={this.props.selected ? () => this.props.onRemoveSaveLater() : this.props.onAddToSave} src={this.props.selected ? likedBtn : heart} alt={this.props.selected ? "Liked" : "Add"} />
                {
                  this.props.added ? 
                  <span style={{...audioCardStyles.favAddIcon}} data-test-id="removeFromPlayList" onClick={() => this.props.removeFromPlayList()}><PlaylistAddCheckIcon /></span> 
                  :
                  <span style={{...audioCardStyles.addIcon}} data-test-id="addToPlayList" onClick={() => this.props.onAddToPlayList()}><PlaylistAdd /></span> 
                }
                </Box>
            </Box>
          <Box sx={{position:"absolute", left: "50%", top:"30%", transform: "translateX(-50%)" }}>
            <img src={playBtn} alt='playIcon' width={"86px"} height={"86px"}
              data-test-id={"playBtnId"}
              onClick={() => this.props.addPlayAudio("BxBlockLogin::Episode", this.props.episodeId)}
              style={{ cursor: "pointer" }}
            />
          </Box>
        <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            >
            <img
              width= {"236px"}
              height= {"224px"}
              src={`${this.props.imageUrl}`}
              alt={"Audio Image"}
            />
        </Box>
        </Box>
        <Box height={"76px"} padding={2}>
            <Typography sx={{fontFamily: "Nexa-Heavy", fontSize:"14px", color:"white", textTransform:"uppercase"}}>
                {this.props.songName}
            </Typography>
            <Typography sx={{fontFamily:"Nexa-Heavy", fontSize:"14px", color:"white"}}>
            {this.props.artist}
            </Typography>
        </Box>
      </Card>
    );
  }
}

export default AudioCard;


export const audioCardStyles = {
    cardContainer: {
        maxWidth: `238px`,
        height:"307px",
        display: "flex",
        flexDirection: "column",
        gap: "0.2rem",
        borderRadius:"16px",
        backgroundColor: "#44403C"
    },
    imageContainer: {
        height:"307px",
        maxWidth: `238px`,
        position: "relative",
    },
    followingText: {
        top: "15px",
        right: "15px",
        position: "absolute",
        color: "white",
        borderRadius: "8px",
        padding: "8px"
    },
    favAddIcon: {
      background: "#030303", 
      borderRadius: "8px", 
      width: "33px", 
      height: "33px", 
      display: "flex", 
      justifyContent :"center", 
      alignItems: "center",
    },
    addIcon: {
      background: "#FF0807", 
      borderRadius: "8px", 
      width: "33px", 
      height: "33px", 
      display: "flex", 
      justifyContent :"center", 
      alignItems: "center",
    }
}