Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.getAllCategoriesDataContentType = "application/json";
exports.getAllCategoriesDataEndPoint = "bx_block_categories/categories";
exports.accountsApiEndpoint = "account_info";
exports.getCategory = "bx_block_categories/categories";
exports.getPodcastApiEndPoint = "bx_block_categories/trending_views/trending_podcast?category_ids[]=8&per_page=10&page=1";
exports.getEpisodesApiEndPoint = "bx_block_categories/trending_views/trending_episode?category_ids[]=8&per_page=10&page=1"
exports.getSubCategoriesPodcastApiEndPoint = "bx_block_categories/sub_categories?category_id=";
exports.getPerPageData = "per_page=20&page=1";
exports.getTrendingPodcastApiEndPoint = "bx_block_categories/trending_views/trending_podcast?category_ids[]=";
exports.getTrendingEpisodeApiEndPoint = "bx_block_categories/trending_views/trending_episode?category_ids[]=";
exports.followPodcastEndPoint = "bx_block_catalogue/followers";
exports.saveEpisode = "bx_block_catalogue/saved_episodes";
exports.likeEpisode = "bx_block_like/likes";
// Customizable Area End