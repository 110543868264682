// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { createRef } from "react";
import moment from "moment";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface CategoryStructure{
    "id": "8",
    "type": "category",
    "attributes": {
        "name": "science",
        "image": {
            "url": "/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6d2d6992001eb7f2f2028b8dd19912c9a5e62764/img.jpg"
        },
        "created_at": "2024-09-17T07:22:23.376Z",
        "updated_at": "2024-09-17T07:22:23.391Z"
    }
}

export interface SubCategoryStructure{
    "id": "1",
    "type": "sub_category",
    "attributes": {
        "id": 1,
        "name": "Pre Primary (kg)",
        "created_at": "2024-06-26T23:32:49.745Z",
        "updated_at": "2024-06-26T23:32:49.745Z",
        "categories": [],
        "image": null
    }
}

export interface S {
  files: unknown[],
  imageUrl: any,
  selectedFile: any,
  serverFileUrl:any,
  fileURL:any,
  fileerror:boolean
}

export interface SS {
  id: any;
}

export default class NewContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  podcastCategoryApiCallId : string = "";
  addEpisodeApiCallId : string = "";
  addUpdateEpisodeApiCallId : string = "";
  podcastSubCategoryApiCallId : string = ";"
  getfileurlApiCallId: string="";
  scrollRef: any = createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      files: [],
      imageUrl: "",
      selectedFile: null,
      serverFileUrl: null,
      fileURL:"",
      fileerror:false,

    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.getfileurlApiCallId &&
      !responseJson.errors
    ) {
      const { url: presignedUrl } = responseJson;
      if (this.state.selectedFile) {
        const uploadResponse = await this.uploadFile(presignedUrl, this.state.selectedFile);
        if (uploadResponse?.success) {
          setStorageData("ProdcastUrl", uploadResponse.url);
        } 
      } else {
        console.error("No file found for upload.");
      }
    }
    if(this.addEpisodeApiCallId === apiRequestCallId){
      this.handleResForAddEpisode(message);
    }
    if(this.addUpdateEpisodeApiCallId === apiRequestCallId){
      this.handleResForUpdateEpisode(message);
    }
  }
  
 
  handleDrop = (event: React.DragEvent<HTMLDivElement>, setFieldValue: any) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (files.length) {
      this.handleFile(files[0]);
    }
  };
  

  handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>, 
    setFieldValue: any
  ) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length) {
      const selectedFile = files[0];
  
      const allowedTypes = ['audio/mpeg', 'video/mp4'];
      if (!allowedTypes.includes(selectedFile.type)) {
        this.setState({fileerror:true})
        return;
      }
      else{
        this.setState({fileerror:false})
      }
  
      const fileUri = URL.createObjectURL(selectedFile);
      this.setState({
        fileURL: fileUri,
        selectedFile,
      });
      setFieldValue("podcastCover", fileUri);  
  
      this.handleFile(selectedFile);
      await this.getfileURL(selectedFile);
    }
  };
  
  
  handleFile = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result as string;
      this.setState({ 
        imageUrl: base64String,
        selectedFile: file 
      });  
    };
    reader.readAsDataURL(file);
  };

  clearFileSelection = (setFieldValue:any) => {
    this.setState({ selectedFile: null, imageUrl: null });
    setFieldValue("podcastCover", null); 
  };
  

  handleSubmit = async (values: any, pathname: string) => {

    try {
      
      values.podcastCover = await getStorageData("ProdcastUrl");

      let newcontentData = await getStorageData("NewContentData", true);


      let parsedData = newcontentData;


      const mergedData = { ...parsedData, ...values };

      await setStorageData("NewContentData", JSON.stringify(mergedData));

      console.log(mergedData, "merged newcontentData");
    
      let token = await getStorageData("token")
      let podcastId = await getStorageData("podcast_id")
      const header = {
        token: JSON.parse(token),
      };
      let formdata = new FormData();
      formdata.append("episode[name]", values.episodeTitle);
      formdata.append("episode[description]", values.description);
      formdata.append("episode[episode_number]", values.episodeNumber);
      formdata.append("episode[episode_type]", values.episodeType);
      formdata.append("podcast_id", podcastId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addEpisodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addEpisodeEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addEpisodeAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  handleSubmitUpdate = async(values: any , pathname : string) => {
    let token = await getStorageData("token")
    let podcastId = await getStorageData("podcast_id")
    const header = {
      token: JSON.parse(token),
    };
    let formdata = new FormData();
    let {publishDay,
      publishMonth,
      publishYear,
      publishHour,
      publishMinute,
      expireDay,expireMonth , expireYear
    } = values;
    
    let formattedDay = expireDay.toString().padStart(2, '0');
    let formattedMonth = expireMonth.toString().padStart(2, '0');

    const formattedTime = moment({
      year: publishYear,
      month: publishMonth - 1,
      day: publishDay,
      hour: publishHour,
      minute: publishMinute,
      second: 9,
    }).format('YYYY-MM-DDTHH:mm:ss');

    const formate_expire_date = `${formattedDay}/${formattedMonth}/${expireYear}`

    let episode_id = await getStorageData("addEpisodeId");
    formdata.append("episode[adult]", values.restrictedEpisode);
    formdata.append("episode[premium]", values.premiumEpisode);
    formdata.append("episode[expiry_date]", formate_expire_date);
    formdata.append("episode[publish_date]", formattedTime );
    formdata.append("episode[explicit_content]", values.explicitContent);
    formdata.append("podcast_id", podcastId);
    formdata.append("episode[status]", formattedTime === "Invalid date" ? "published" : "scheduled");
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addUpdateEpisodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateEpisodeEndPoint}/${JSON.parse(episode_id)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateEpisodeAPiMethod
    );
   runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  

  uploadFile = async (presignedUrl: string, file: File) => {
    try {
      const fileData = await file.arrayBuffer(); 
      const response = await fetch(presignedUrl, {
        method: "PUT",
        body: fileData,
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
  
      if (response.ok) {
        const fileUrl = presignedUrl.split("?")[0];
        return { success: true, url: fileUrl };
      } else {
        const errorText = await response.text();
        return { success: false, message: errorText };
      }
    } catch (error) {
     console.log(error)
    }
  };


  handleResForAddEpisode = async(message : Message) => {
    const responseJson =  message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
     let episode_id = responseJson.data.id;
    await setStorageData("addEpisodeId",episode_id);   
     this.props.handleCommonPath("NewContentAdds");
  }

  handleResForUpdateEpisode = (message : Message) => {  
      this.props.handleCommonPath("MediaManagementNewContent");
  }


  getShortFileName = (fileName:any) => {
    const MAX_LENGTH = 30; // Max characters to display
    const fileExtension = fileName.slice(fileName.lastIndexOf('.')); // Get the file extension
    const fileBaseName = fileName.slice(0, fileName.lastIndexOf('.')); // Get the name without extension
  
    if (fileBaseName.length > MAX_LENGTH) {
      return `${fileBaseName.slice(0, MAX_LENGTH)}...${fileExtension}`;
    }
    return fileName; // Return the full name if it's short
  };

  getfileURL = async (file: File) => {
    try {
      const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: JSON.parse(token),
      };
      const body = {
        file_name: file.name, // Send file name to the server
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getfileurlApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.geturl
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } catch (error) {
      console.error("Failed to fetch presigned URL:", error);
    }
  };

  async componentDidMount() {
    super.componentDidMount();
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }
}
// Customizable Area End
